import React from 'react'
import RelativeContainer from '../containers/RelativeContainer'

function Option({ option, selected, width, select }) {

    return (
        <RelativeContainer
            onClick={select}
            style={{
                height: 50,
                cursor: 'pointer',
                color: selected ? 'black' : 'white',
                boxSizing: 'border-box',
                borderBottom: '1px solid #cdcdcd',
                textAlign: 'center',
                fontSize: '15px',
                padding: '0px 5px',
                background: selected ? '#fafafa' : '#777',
                width
            }}
        >
            {option.title}
        </RelativeContainer>
    )
}

export default ({ options, selectedIndex, select }) => {

    return (
        <RelativeContainer
            style={{
                height: 50,
                fontFamily: 'Muli',
                flexFlow: 'row'
            }}
        >
            {options.map((option, index) =>
                <Option key={`optionbox_option_${index}`} option={option} select={() => select(index)} selected={selectedIndex === index} width={`${100 / Math.max(options.length, 1)}%`} />
            )}
        </RelativeContainer>
    )
}