import React, { useState, useCallback, useMemo, useEffect } from 'react'

import AbsoluteContainer from "../containers/AbsoluteContainer"
import RelativeContainer from '../containers/RelativeContainer'
import ImageButton from '../buttons/ImageButton'

import hamburger from './hamburger.svg'
import accountIcon from '../../icons/account.svg'
import useSlideIn from '../../hooks/useSlideIn'

function MobileMenuLink({ title, onClick, selected, width, height }) {

    return (
        <RelativeContainer
            style={{
                width,
                height,
                display: 'flex',
                color: 'white',
                justifyContent: 'center',
                lineHeight: '30px',
                alignItems: 'flex-end'
            }}
        >
            <RelativeContainer
                onClick={onClick}
                style={{
                    cursor: 'pointer',
                    fontWeight: selected ? 700 : 300,
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                    width: 'auto',
                }}
            >
                {title.toUpperCase()}
            </RelativeContainer>
</RelativeContainer>
    )

}


export default ({ height, pages, width, navigateTo, selectedIndex, activateAccountPage, user }) => {

    const [active, setActive] = useState(false)

    const handleToggle = useCallback(() => {
        setActive(!active)
    }, [active])

    const menuItemWidth = width / 3

    const menuPages = useMemo(() => pages.map(page => page.menu ? page : null), [pages])

    const slideIn = useSlideIn({ x: 30, show: active })

    const handleAccountClick = useCallback(e => {
        e.stopPropagation()
        activateAccountPage()
    }, [activateAccountPage])

    useEffect(() => {
        setActive(false)
    }, [selectedIndex])

    useEffect(() => {
        if (user) {
            setActive(false)
        }

    }, [user])

    return (
        <AbsoluteContainer
            onClick={e => e.stopPropagation()}
            style={{
                position: 'fixed',
                height,
                width,
                pointerEvents: active ? 'auto' : 'none',
                top: '0px',
                zIndex: 3,
            }}
        >
            <AbsoluteContainer
                style={{
                    pointerEvents: 'none',
                    //background: 'linear-gradient(to left, #121212, #323232)',
                    background: 'rgba(0,0,0,0.9)',
                    opacity: active ? 0.9 : 0,
                    transition: 'opacity 250ms'
                }}
            />
            <AbsoluteContainer
                style={{
                    pointerEvents: 'auto',
                    height: 50,
                    width: 50,
                    transition: 'all 250ms ease',
                    top: '0px',
                    filter: 'drop-shadow(0 0 4px #111)',
                    right: '0px'
                }}
            >
                <ImageButton onClick={handleToggle} invert={true} src={hamburger} height={25} width={25} />
            </AbsoluteContainer>
            <AbsoluteContainer
                style={{
                    pointerEvents: active ? 'auto' : 'none',
                    opacity: active ? 1 : 0,
                    height: 50,
                    width: 50,
                    transition: 'opacity 250ms ease',
                    top: '0px',
                    left: '0px'
                }}
            >
                <ImageButton onClick={handleAccountClick} invert={active} src={accountIcon} height={25} width={25} />
            </AbsoluteContainer>
            <AbsoluteContainer
                onClick={handleAccountClick}
                style={{
                    pointerEvents: active ? 'auto' : 'none',
                    opacity: active ? 1 : 0,
                    alignItems: 'flex-start',
                    height: 50,
                    fontSize: '14px',
                    color: 'white',
                    width: 'auto',
                    transition: 'opacity 250ms ease',
                    top: '0px',
                    left: '50px'
                }}
            >
                {user ? user.email : 'SIGN IN'}
            </AbsoluteContainer>
            <AbsoluteContainer
                style={{
                    pointerEvents: active ? 'auto' : 'none',
                    height: pages.length * 30,
                    justifyContent: 'flex-start',
                    width: menuItemWidth + 40,
                    transition: 'all 250ms ease',
                    top: 60,
                    right: '0px',
                    ...slideIn
                }}
            >
                {
                    menuPages.map((page, index) => <MobileMenuLink key={`mobile_menu_item_${index}`} height={30} title={page.name} width={menuItemWidth} selected={index === selectedIndex} onClick={() => navigateTo(index)} />)
                }
            </AbsoluteContainer>
        </AbsoluteContainer>
    )


}