import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { animateScroll as scroll } from "react-scroll";

import RelativeContainer from "./components/containers/RelativeContainer";

import Header from "./components/header/Header";
import useDocDimensions from "./hooks/useDocDimensions";
import useUser from "./hooks/useUser";
import pages from "./components/pages/pages";
import AccountPage from "./components/pages/AccountPage";
import Footer from "./components/footer/Footer";
import AbsoluteContainer from "./components/containers/AbsoluteContainer";
import PageHeader from "./components/pages/PageHeader";
import TrackDialog from "./components/track/TrackDialog";
import useSelectedSession from "./hooks/useSelectedSession";
import useErrorCodes from "./hooks/useErrorCodes";

function FooterSpacer({ trackData, height }) {
  return (
    <RelativeContainer
      style={{
        height: trackData && trackData.currentSession ? height : 0,
        transition: "height 500ms",
      }}
    />
  );
}

function Background() {
  return (
    <AbsoluteContainer
      className="background1"
      style={{
        top: "0",
        left: "0",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        pointerEvents: "none",
      }}
    ></AbsoluteContainer>
  );
}

const menuHeight = 40;
const footerHeight = 90;

function AppContents({ loaded, match }) {
  const {
    user,
    trackData,
    clients,
    updateClientInfo,
    admin,
    signIn,
    signOut,
    registerAccount,
  } = useUser();
  const {
    selectSession,
    selectedSession,
    handleDialogSubmit,
    promptNewSession,
  } = useSelectedSession({ trackData });

  const { assignErrorCode } = useErrorCodes();

  const { width, height } = useDocDimensions();

  const contentWidth = useMemo(() => Math.min(width * 0.95, 600), [width]);

  const bodyHeight = useMemo(
    () =>
      height -
      (trackData && trackData.currentSession ? footerHeight : 0) -
      menuHeight,
    [height, trackData]
  );
  const [message, setMessage] = useState("");

  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const history = useHistory();
  const { pathname } = useLocation();

  const scrollPos = useScrollPosition();

  const [selectedPage, setSelectedPage] = useState(null);

  const [accountPageActive, setAccountPageActive] = useState(false);

  const injectedProps = useMemo(() => {
    const generateInvoice = ({ client, records }) => {
      setSelectedInvoice({ client, records });
      history.push("/invoices");
    };
    return {
      clients: {
        clients,
        trackData,
        updateClientInfo,
        generateInvoice,
      },
      track: {
        trackData,
      },
      invoices: {
        selectedInvoice,
      },
    };
  }, [
    clients,
    trackData,
    updateClientInfo,
    selectedInvoice,
    history,
  ]);

  useEffect(() => {
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].path === pathname) {
        setSelectedPage(i);
      }
    }
  }, [pathname]);

  const navigateTo = useCallback(
    (index) => {
      setSelectedPage(index);

      scroll.scrollTo(0, {
        duration: 250,
        delay: 0,
        smooth: "easeInOutCubic",
      });
      setTimeout(() => {
        history.push(pages[index].path);
      }, 200);
    },
    [history]
  );

  const handleSignIn = (fields, callback) => {
    signIn(fields, ({ error, success }) => {
      callback({ error, success });
      if (success) {
        setAccountPageActive(false);
      }
    });
  };

  return (
    <RelativeContainer
      style={{ height: "auto", minHeight: height, width, display: "block" }}
    >
      <Background />
      <Header
        user={user}
        accountPageActive={accountPageActive}
        activateAccountPage={() => setAccountPageActive((active) => !active)}
        width={width}
        screenHeight={height}
        admin={admin}
        scroll={scrollPos}
        selectedIndex={selectedPage}
        height={menuHeight}
        navigateTo={navigateTo}
        pages={pages}
      />
      {/* <Spacer width={spacerWidth}/> */}
      <PageHeader
        onClick={message ? () => setAccountPageActive(true) : null}
        message={message}
      />
      <Switch>
        {pages.map((page, index) => (
          <Route
            key={page.path}
            exact={page.path === "/"}
            path={page.path}
            render={() =>
              React.createElement(page.component, {
                selected: selectedPage === index,
                contentWidth,
                bodyHeight,
                selectSession,
                setMessage,
                screenHeight: height,
                assignErrorCode,
                screenWidth: width,
                scroll: scrollPos,
                user,
                ...injectedProps[page.name],
              })
            }
          />
        ))}
      </Switch>
      <FooterSpacer height={footerHeight} trackData={trackData} />
      <Footer
        user={user}
        promptNewSession={promptNewSession}
        trackData={trackData}
      />
      <TrackDialog
        width={contentWidth}
        trackData={trackData}
        deactivate={() => selectSession(null)}
        selectedSession={selectedSession}
        callback={handleDialogSubmit}
        title={
          selectedSession
            ? selectedSession.id
              ? "Edit Session"
              : "Begin Session"
            : ""
        }
        clients={clients}
      />
      <AccountPage
        contentWidth={contentWidth}
        user={user}
        height={height}
        signOut={signOut}
        signIn={handleSignIn}
        registerAccount={registerAccount}
        active={accountPageActive}
        deactivate={() => setAccountPageActive(false)}
      />
    </RelativeContainer>
  );
}

function App() {
  return (
    <Router>
      <AppContents />
    </Router>
  );
}

export default App;
