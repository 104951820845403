import React from 'react'
import {useDrag} from 'react-use-gesture'

function ValueTicker({ bind, value, color }) {
    return (
      <span
        {...bind()}
        style={{
          color,
          cursor: "pointer",
        }}
      >
        {value}
      </span>
    );
  }

export default function TimeTicker({
    active,
    minColor,
    hourColor,
    minutes,
    hours,
    displayedMinutes,
    displayedHours,
    setHours,
    setMinutes,
    setTempHours,
    setTempMinutes,
  }) {
    const bindClockHours = useDrag(({ down, movement: [_, my] }) => {
      requestAnimationFrame(() => {
        const offset = Math.round(my / 60);
        let rem = (hours - offset) % 12;
  
        let newHours = Math.round(rem >= 0 ? rem : rem + 12);
        if (down) {
          setTempHours(newHours);
        } else {
          setHours(newHours);
          setTempHours(null);
        }
      });
    });
  
    const bindClockMins = useDrag(({ down, movement: [_, my] }) => {
      requestAnimationFrame(() => {
        const offset = Math.round(my / 20);
  
        let rem = (minutes - offset) % 60;
  
        let newMins = Math.floor(rem >= 0 ? rem : rem + 60);
  
        if (down) {
          setTempMinutes(newMins);
        } else {
          setMinutes(newMins);
          setTempMinutes(null);
        }
      });
    });
  
    return (
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          height: "auto",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: active ? "auto" : "none",
        }}
      >
        <ValueTicker
          color={hourColor}
          bind={bindClockHours}
          value={Math.round(displayedHours) % 12 || 12}
        />
        <span>:</span>
        <ValueTicker
          color={minColor}
          bind={bindClockMins}
          value={
            Math.floor(displayedMinutes) < 10
              ? `0${Math.floor(displayedMinutes)}`
              : Math.floor(displayedMinutes)
          }
        />
      </div>
    );
  }
  