import React from "react";
import RelativeContainer from "../containers/RelativeContainer";
import Button from "./Button";

export default function SubmitButton({ active, loading, onSubmit, text, deactivate }) {
  return (
    <RelativeContainer
      style={{
        overflow: "hidden",
        height: active || deactivate ? 50 : 0,
        background: "#777",
        flexFlow: "row",
      }}
    >
      <RelativeContainer
        style={{
          width: deactivate && !loading ? "50%" : "100%",
          transition: "width 250ms",
        }}
      >
        <Button loading={loading} text={text} onClick={onSubmit} />
      </RelativeContainer>
      {deactivate ? (
        <RelativeContainer
          style={{
            width: loading ? "0%" : "50%",
            transition: "width 250ms",
          }}
        >
          <Button loading={loading} text="CANCEL" onClick={deactivate} />
        </RelativeContainer>
      ) : null}
    </RelativeContainer>
  );
}
