import React from 'react'
import { isMobile } from 'react-device-detect'
import RelativeContainer from '../containers/RelativeContainer'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

export default ({ pages, screenHeight, height, navigateTo, selectedIndex, width, user, accountPageActive, activateAccountPage }) => {

    return (
        <RelativeContainer
            style={{
                position: 'sticky',
                width,
                zIndex: '2',
                top: '0px',
                height,
                pointerEvents: accountPageActive ? 'none' : 'auto',
                flexFlow: 'row',
                justifyContent: 'flex-start',
                transition: 'height 250ms',
            }}
        >
            {
                isMobile ?
                    <MobileMenu user={user} accountPageActive={accountPageActive} activateAccountPage={activateAccountPage} width={width}  height={screenHeight} selectedIndex={selectedIndex} navigateTo={navigateTo} pages={pages}/>
                    :
                    <DesktopMenu user={user} accountPageActive={accountPageActive} activateAccountPage={activateAccountPage}  selectedIndex={selectedIndex} navigateTo={navigateTo} pages={pages}/>
            }

        </RelativeContainer>
    )
}