import React, { useRef } from "react";
import RelativeContainer from "../containers/RelativeContainer";
import Invoice from "../invoices/NewInvoice";
import userLogo from "../../icons/logo.svg";
import exampleInvoice from "../invoices/exampleInvoice";

// const a4width = 210;
// const a4height = 297;

const exampleUser = {
  name: "Free Lancer",
  addressLine1: "1241 E Lancer Ave",
  addressLine2: "Unit B",
  addressLevel2: "Tucson",
  addressLevel1: "Arizona",
  postalCode: "85700",
  country: "United States",
  email: "freelancer@worktrack.app",
  phone: "112-2334",
  logo: userLogo,
};


export default ({ selectedInvoice, user, bodyHeight, screenHeight, screenWidth }) => {
  const invoiceRef = useRef();

  return (
    <RelativeContainer
      style={{
        height: bodyHeight,
        transition: "height 500ms",
      }}
    >
      <Invoice
        ref={invoiceRef}
        screenWidth={screenWidth}
        example={!selectedInvoice}
        bodyHeight={bodyHeight}
        screenHeight={screenHeight}
        invoiceData={selectedInvoice || exampleInvoice}
        user={user || exampleUser}
      />
    </RelativeContainer>
  );
};
