export const dateTimeFormat = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export const getNumHours = (durationMs) => {
  return durationMs / 1000 / 60 / 60;
};

export const getDateString = (startTime, endTime, time = true) => {
  if (startTime) {
    let [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
      ,
      { value: hour },
      ,
      { value: minute },
    ] = dateTimeFormat.formatToParts(new Date(startTime));

    let startTimeString = `${month} ${day},${year} ${time ? `${hour}:${minute}` : ''}`;
    if (endTime) {
      let [
        { value: endMonth },
        ,
        { value: endDay },
        ,
        { value: endYear },
        ,
        { value: endHour },
        ,
        { value: endMinute },
      ] = dateTimeFormat.formatToParts(new Date(endTime));
      let endTimeString = `${endMonth} ${endDay},${endYear} ${time ? `${endHour}:${endMinute}` : ''}`;
      return `${startTimeString} - ${endTimeString}`;
    }
    return `${startTimeString}`;
  } else {
    return "ERROR: NO START TIME";
  }
};

export const getDurationString = (durationMs) => {
  if (durationMs) {
    let numHours = Math.floor(getNumHours(durationMs));
    let modMins = durationMs - numHours * 1000 * 60 * 60;
    let numMins = Math.floor(modMins / 1000 / 60);
    let modSecs = modMins - numMins * 1000 * 60;
    let numSecs = Math.floor(modSecs / 1000);
    return `${numHours ? `${numHours}h ` : ""}${
      numMins ? `${numMins}m ` : ""
    }${numSecs}s`;
  } else {
    return "";
  }
};

export const getShortDurationString = (durationMs) => {
  if (durationMs) {
    let numHours = Math.floor(durationMs / 1000 / 60 / 60);
    let modMins = durationMs - numHours * 1000 * 60 * 60;
    let numMins = Math.floor(modMins / 1000 / 60);
    let modSecs = modMins - numMins * 1000 * 60;
    let numSecs = Math.floor(modSecs / 1000);
    return `${numHours ? numHours + ":" : ""}${
      numMins ? numMins.toString().padStart(numHours ? 2 : 1, "0") : ""
    }:${numSecs.toString().padStart(2, "0")}`;
  } else {
    return "";
  }
};
