import React from "react";

export default function CancelButton({ onClick, active }) {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        height: "auto",
        marginBottom: "5px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span
        onMouseDown={onClick}
        style={{
          cursor: "pointer",
          color: "#ababab",
          fontSize: "12px",
          pointerEvents: active ? "auto" : "none",
        }}
      >
        CANCEL
      </span>
    </div>
  );
}
