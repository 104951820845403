import React, { useState, useMemo, useCallback, useEffect } from "react";
import RelativeContainer from "../containers/RelativeContainer";
import TextInput from "./TextInput";
import AbsoluteContainer from "../containers/AbsoluteContainer";
import TextSpan from "../text/TextSpan";

function Suggestion({ option, select, onHover, hovered }) {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      select();
    },
    [select]
  );

  return (
    <RelativeContainer
      onClick={handleClick}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      style={{
        pointerEvents: "auto",
        background: hovered ? "#fafafa" : "white",
        height: 50,
      }}
    >
      <TextSpan text={option} />
    </RelativeContainer>
  );
}

export default ({
  options,
  height = 50,
  value,
  index,
  length,
  onChange,
  ...props
}) => {
  // const matches = useMemo(() => options ? value ? options.filter(option => option.indexOf(value) !== -1) : options : [], [options, value])

  const [focused, setFocused] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const containerHeight = useMemo(
    () => (options ? (focused ? Math.min(options.length, 3) * height : 0) : 0),
    [options, focused, height]
  );

  const handlePress = useCallback(
    ({ keyCode }) => {
      if (focused) {
        if (options) {
          //UP ARROW
          if (keyCode === 38) {
            setHoveredIndex((index) => Math.max(index - 1, 0));
          }

          //DOWN ARROW
          if (keyCode === 40) {
            setHoveredIndex((index) => Math.min(index + 1, options.length - 1));
          }

          if (keyCode === 13) {
            if (hoveredIndex !== null) {
              onChange(options[hoveredIndex]);
              setFocused(false);
            }
          }
        }
      }
    },
    [options, hoveredIndex, onChange, focused]
  );

  useEffect(() => {
    document.addEventListener("keydown", handlePress);

    return () => document.removeEventListener("keydown", handlePress);
  }, [handlePress]);

  return (
    <RelativeContainer
      style={{
        height,
        background: focused ? "white" : "#fafafa",
        overflow: "visible",
      }}
    >
      <TextInput
        value={value}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{ borderBottom: "0px solid #fafafa" }}
        {...props}
      ></TextInput>
      <AbsoluteContainer
        className="scroll"
        style={{
          top: height,
          borderBottom: "1px solid #cdcdcd",
          pointerEvents: "auto",
          zIndex: 2 + length - index,
          height: containerHeight,
          transition: "height 250ms",
          transitionDelay: "100ms",
          flexFlow: "column",
          justifyContent: "flex-start",
          overflowY: "auto",
        }}
      >
        <RelativeContainer
          style={{
            justifyContent: "flex-start",
            height: "auto",
          }}
        >
          {options
            ? options.map((option, index) => (
                <Suggestion
                  key={`suggestion_${option}_${index}`}
                  hovered={hoveredIndex === index}
                  select={() => onChange(option)}
                  onHover={() => setHoveredIndex(index)}
                  option={option}
                  first={index === 0}
                  value={value}
                  height={height}
                />
              ))
            : null}
        </RelativeContainer>
      </AbsoluteContainer>
    </RelativeContainer>
  );
};
