import React from 'react'

import RelativeContainer from '../containers/RelativeContainer'

export default function TextSpan({ text, style }) {

    return (
        <RelativeContainer
            style={{
                height: 'auto',
                boxSizing: 'border-box',
                fontSize: '18px',
                alignItems: 'flex-start',
                padding: '5px 20px',
                fontFamily: 'Muli',
                ...style
            }}
        >
            {text}
        </RelativeContainer>
    )
}
