import React from 'react'
import RelativeContainer from '../containers/RelativeContainer'

export default ({ src, height, width, title, onClick, invert }) => {


    return (
        <RelativeContainer
            onClick={onClick}
            style={{
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                height: height || '100%',
                width: width || '100%',
            }}
        >
            <img title={title} alt={title} src={src} style={{
                height: '100%', 
                objectFit: 'contain', 
                filter: `invert(${invert ? 100 : 0}%)`,
                transition: 'filter 250ms ease'
            }} />
        </RelativeContainer>
    )
}