import React from "react";
import useSlideIn from "../../hooks/useSlideIn";
import AbsoluteContainer from "../containers/AbsoluteContainer";
import ResizeObserver from "react-resize-observer";
import TextInput from "../inputs/TextInput";
import { useSpring, animated } from "react-spring";
import RelativeContainer from "../containers/RelativeContainer";

const buttonStyles = {
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  cursor: "pointer",
};

function ClientInfoSubmitButton({ changes, onSubmit, onCancel, onReset, saveText, alwaysSave }) {
  const resetSpring = useSpring({
    width: changes ? "50%" : "0%",
    opacity: changes ? 1 : 0,
    //borderRight: `${changes ? 0.5 : 0}px solid #cdcdcd`,
  });
  const confirmSpring = useSpring({
    width: (changes || alwaysSave) ? "50%" : "0%",
    opacity: (changes || alwaysSave) ? 1 : 0,
    // borderLeft: `${changes ? 0.5 : 0}px solid #cdcdcd`,
  });
  const cancelSpring = useSpring({
    width: changes ? "0%" : alwaysSave ? '50%' : "100%",
    borderLeft: `${changes ? 0.5 : 0}px solid #cdcdcd`,
    borderRight: `${changes || alwaysSave ? 0.5 : 0}px solid #cdcdcd`,
  });

  return (
    <RelativeContainer
      style={{
        height: 50,
        borderTop: "1px solid #cdcdcd",
        borderBottom: "1px solid #cdcdcd",
        boxSizing: "border-box",
        flexFlow: "row",
      }}
    >
      <animated.div
        onClick={onReset}
        style={{
          pointerEvents: changes ? "auto" : "none",
          ...buttonStyles,
          ...resetSpring,
        }}
      >
        RESET
      </animated.div>
      <animated.div
        onClick={onCancel}
        style={{
          ...buttonStyles,
          ...cancelSpring,
        }}
      >
        CANCEL
      </animated.div>
      <animated.div
        onClick={onSubmit}
        style={{
          pointerEvents: (changes || alwaysSave) ? "auto" : "none",
          ...buttonStyles,
          ...confirmSpring,
        }}
      >
        {saveText || 'SAVE'}
      </animated.div>
    </RelativeContainer>
  );
}

export default function ClientInfo({
  client,
  active,
  onResize,
  handleFieldChange,
  handleRateChange,
  title,
  alwaysSave,
  saveText,
  handleRateBlur,
  onReset,
  onSubmit,
  onCancel,
  changes,
  error,
}) {
  const slideIn = useSlideIn({ show: active });

  return (
    <AbsoluteContainer
      style={{
        left: 0,
        right: 0,
        background: "#fafafa",
        height: "auto",
        ...slideIn,
        justifyContent: "flex-start",
      }}
    >
      <ResizeObserver onResize={onResize} />
      {title ? (
        <RelativeContainer
          style={{
            height: 50,
            fontSize: '16px',
            borderBottom: '1px solid #cdcdcd'
          }}
        >
          {title}
        </RelativeContainer>
      ) : null}
      <TextInput
        placeholder="Client Name"
        style={{ borderBottom: 0 }}
        value={client ? client.name : ""}
        onChange={(val) => handleFieldChange("name", val)}
      />
      <TextInput
        style={{ borderBottom: 0 }}
        onBlur={handleRateBlur}
        value={client && client.rate ? `$${client.rate}` : ""}
        onChange={handleRateChange}
        placeholder="Default Rate"
      />
      <TextInput
        style={{ borderBottom: 0 }}
        autocomplete="new-password"
        value={client ? client.email : ""}
        onChange={(val) => handleFieldChange("email", val)}
        placeholder="Client Email"
      />
      <TextInput
        style={{ borderBottom: 0 }}
        value={client ? client.companyName : ""}
        onChange={(val) => handleFieldChange("companyName", val)}
        placeholder="Company Name (optional)"
      />
      <ClientInfoSubmitButton
        onReset={onReset}
        alwaysSave={alwaysSave}
        changes={changes}
        saveText={saveText}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      {error ? (
        <RelativeContainer
          style={{
            height: "auto",
            fontSize: "16px",
            color: "#640a0a",
            padding: "10px 0px",
          }}
        >
          {error}
        </RelativeContainer>
      ) : null}
    </AbsoluteContainer>
  );
}
