import React, { useEffect } from "react";
import RelativeContainer from "../containers/RelativeContainer";
import TrackList from "./TrackList";

export default function TrackPanel({
  trackData,
  user,
  selectSession,
  setMessage,
  contentWidth,
}) {
  useEffect(() => {
    if (!user) {
      setMessage("Sign In to track time");
    } else {
      if (trackData && trackData.track && !trackData.track.length) {
        setMessage(
          "No sessions recorded. Press the record button to create a session."
        );
      } else {
        setMessage("");
      }
    }

    return () => setMessage("");
  }, [user, setMessage, trackData]);

  return (
    <RelativeContainer
      style={{ height: "auto", justifyContent: "flex-start", paddingTop: 20 }}
    >
      {user ? (
        trackData ? (
          <>
            <TrackList
              trackData={trackData}
              width={contentWidth}
              selectSession={selectSession}
              user={user}
            />
          </>
        ) : null
      ) : null}
    </RelativeContainer>
  );
}
