import { useState, useRef, useEffect, useCallback } from "react";

export default (user, db, collectionName) => {
  const [data, setData] = useState(null);

  const subscribeRef = useRef();

  useEffect(() => {
    if (user && collectionName) {
      subscribeRef.current = db
        .collection("users")
        .doc(user.id)
        .collection(collectionName)
        .onSnapshot((snap) => {
          setData(snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        });
    } else {
      setData(null);
    }
    return () => {
      if (subscribeRef.current) {
        subscribeRef.current();
      }
    };
  }, [user, collectionName, db]);

  const update = useCallback(
    (item, callback) => {
      if (item.id) {
        let docId = item.id;
        delete item.id;
        Object.keys(item).forEach((key) => {
          if (!item[key]) delete item[key];
        });
        db.collection("users")
          .doc(user.id)
          .collection(collectionName)
          .doc(docId)
          .set(item)
          .then(() => {
            callback({ success: true });
          })
          .catch((err) => {
            console.log(err);
            callback({
              error: "error updating document - internal error please report",
            });
          });
      } else {
        callback({
          error: "document has no id - internal error please report",
        });
      }
    },
    [user, db, collectionName]
  );

  return { data, update };
};
