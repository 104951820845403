import React, {useState, useRef, forwardRef, useImperativeHandle} from 'react'
import AbsoluteContainer from '../containers/AbsoluteContainer'
import RelativeContainer from '../containers/RelativeContainer'

export default forwardRef((props, ref) => {

    const [active, setActive] = useState(false)
    const [message, setMessage] = useState('')
    const yesCallbackRef = useRef()
    const noCallbackRef = useRef()

    useImperativeHandle(ref, () => ({
        prompt: (message, yesCallback, noCallback) => {
            setActive(true)
            setMessage(message)
            yesCallbackRef.current = yesCallback
            noCallbackRef.current = noCallback
        }
    }))

    const handleYes = () => {
        if (yesCallbackRef.current) {
            yesCallbackRef.current()
        }
        setActive(false)
    }

    const handleNo = () => {
        if (noCallbackRef.current) {
            noCallbackRef.current()
        }
        setActive(false)
    }

    return (
        <AbsoluteContainer
            onClick={e => e.stopPropagation()}
            style={{
                left: 0,
                top: 0,
                opacity: active ? 1 : 0,
                background: 'rgba(255,255,255,0.95)',
                pointerEvents: active ? 'auto' : 'none',
                fontFamily: 'Muli',
                transition: 'opacity 500ms'
            }}
        >
            <RelativeContainer
                style={{
                    height: 'auto',
                    padding: '10px 15px'
                }}
            >
                {message}
            </RelativeContainer>
            <RelativeContainer
                style={{
                    height: 40,
                    flexFlow: 'row'
                }}
            >
                <RelativeContainer onClick={handleYes} style={{ width: '50%', cursor: 'pointer' }}>
                    YES
                </RelativeContainer>
                <RelativeContainer onClick={handleNo} style={{ width: '50%', cursor: 'pointer' }}>
                    NO
                </RelativeContainer>
            </RelativeContainer>
        </AbsoluteContainer>
    )
})

