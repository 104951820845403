import React, {
  useState,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import ResizeObserver from "react-resize-observer";
import { useSpring, animated } from "react-spring";
import "./timePickerStyles.css";
import ClockBackground from "./ClockBackground";
import CancelButton from "./CancelButton";
import ConfirmButton from "./ConfirmButton";
import TimeTicker from "./TimeTicker";
import AMPMButton from "./AMPMButton";
import ProgressCircle from "./ProgressCircle";

export default forwardRef(function TimerPicker(
  { hourColor = "#1CD2DB", minColor = "#1E88EB" },
  ref
) {
  const [size, setSize] = useState(300);
  const [active, setActive] = useState(false);

  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [pm, setPm] = useState(false);

  const [tempMinutes, setTempMinutes] = useState(null);
  const [tempHours, setTempHours] = useState(null);

  const confirmCallbackRef = useRef();
  const cancelCallbackRef = useRef();

  const handleConfirm = useCallback(() => {
    if (confirmCallbackRef.current) {
      confirmCallbackRef.current({
        minutes: Math.floor(minutes),
        hours: pm ? Math.floor(hours + 11) : Math.floor(hours),
      });
    }
    setActive(false);
  }, [minutes, hours, pm]);

  const handleCancel = useCallback(() => {
    if (cancelCallbackRef.current) {
      cancelCallbackRef.current({
        minutes: Math.floor(minutes),
        hours: pm ? Math.floor(hours + 11) : Math.floor(hours),
      });
    }
    setActive(false);
  }, [minutes, hours, pm]);

  useImperativeHandle(ref, () => ({
    promptTime: (initTime, confirmCallback, cancelCallback) => {
      let time = new Date();
      if (initTime) {
        if (initTime instanceof Date) {
          time = initTime;
        } else {
          console.error("TIME IS NOT OF DATE TYPE");
        }
      }

      const mins = time.getMinutes();
      const hrs = time.getHours() % 12;
      setMinutes(mins);
      setHours(hrs);
      setPm(time.getHours() > 11);

      confirmCallbackRef.current = confirmCallback;
      cancelCallbackRef.current = cancelCallback;

      setActive(true);
    },
  }));

  const clockSpring = useSpring({
    height: active ? size : size / 2,
    width: active ? size : size / 2,
  });
  const { innerScale } = useSpring({
    innerScale: active ? size / 250 : size / 500,
  });

  const handleMinuteDrag = (progress, down) => {
    if (down) {
      setMinutes(progress * 60);
    } else {
      setMinutes(Math.round(progress * 60));
    }
  };

  const handleHourDrag = (progress, down) => {
    if (down) {
      setHours(progress * 12);
    } else {
      setHours(Math.round(progress * 12));
    }
  };

  const fade = useSpring({ opacity: active ? 1 : 0 });

  const displayedMinutes = useMemo(
    () => (tempMinutes !== null ? tempMinutes : minutes),
    [tempMinutes, minutes]
  );
  const displayedHours = useMemo(
    () => (tempHours !== null ? tempHours : hours),
    [tempHours, hours]
  );

  const minuteProgress = useMemo(() => displayedMinutes / 60, [
    displayedMinutes,
  ]);
  const hourProgress = useMemo(() => displayedHours / 12, [displayedHours]);

  return (
    <animated.div
      className="timePicker"
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: active ? "auto" : "none",
        ...fade,
      }}
    >
      <ResizeObserver
        onResize={({ width, height }) => setSize(Math.min(height, width))}
      />
      <animated.svg viewBox="-300 -300 600 600" {...clockSpring}>
        <ClockBackground />
        <ProgressCircle
          radius={200}
          active={active}
          color={hourColor}
          progress={hourProgress}
          onChange={handleHourDrag}
        />
        <ProgressCircle
          radius={250}
          color={minColor}
          active={active}
          progress={minuteProgress}
          onChange={handleMinuteDrag}
        />
      </animated.svg>
      <animated.div
        style={{
          height: "100%",
          position: "absolute",
          pointerEvents: "none",
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          color: "white",
          transform: innerScale.interpolate((scale) => `scale(${scale})`),
          fontSize: `32px`,
        }}
      >
        <CancelButton active={active} onClick={handleCancel} />
        <TimeTicker
          active={active}
          setTempMinutes={setTempMinutes}
          setTempHours={setTempHours}
          setMinutes={setMinutes}
          setHours={setHours}
          onChangeHours={setHours}
          onChangeMinutes={setMinutes}
          tempHours={tempHours}
          tempMinutes={tempMinutes}
          minutes={minutes}
          hours={hours}
          displayedMinutes={displayedMinutes}
          displayedHours={displayedHours}
          hourColor={hourColor}
          minColor={minColor}
        />
        <AMPMButton active={active} pm={pm} setPm={setPm} />
        <ConfirmButton active={active} onClick={handleConfirm} />
      </animated.div>
    </animated.div>
  );
});
