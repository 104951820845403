import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import RelativeContainer from "../containers/RelativeContainer";
import ResizeObserver from "react-resize-observer";
import TextSpan from "../text/TextSpan";
import { getDurationString, getDateString } from "../../utils/timeUtils";
import AbsoluteContainer from "../containers/AbsoluteContainer";
import DeleteButton from "../track/DeleteButton";
import ConfirmBox from "../inputs/ConfirmBox";
import infoIcon from "../../icons/info.svg";
import "./drpStyles.css";

import DatePicker from "react-date-picker";
import useSlideIn from "../../hooks/useSlideIn";
import { useSpring, animated } from "react-spring";
import useEditedClientInfo from "./useEditedClientInfo";
import useFilteredRecords from "./useFilteredRecords";
import ClientInfo from "./ClientInfo";

function ExpandArrow({ expanded, expand }) {
  const [hovered, setHovered] = useState(false);

  return (
    <AbsoluteContainer
      onClick={expand}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        bottom: 0,
        opacity: hovered ? 1 : 0.5,
        cursor: "pointer",
        height: 30,
      }}
    >
      <svg
        viewBox="0 0 100 100"
        style={{
          height: 30,
          width: 60,
          transform: `scaleY(${expanded ? -1 : 1})`,
          transition: "transform 500ms",
        }}
      >
        <path
          stroke="black"
          strokeWidth={2}
          fill="none"
          d="M20 40 L50 60 L80 40"
        ></path>
      </svg>
    </AbsoluteContainer>
  );
}

function ClientRecord({ record, trackData, selectSession, index }) {
  const confirmBoxRef = useRef();

  const { currentSession, deleteSession, duration } = trackData;

  const isCurrent = useMemo(
    () => currentSession && currentSession.id === record.id,
    [record, currentSession]
  );

  const handleDelete = useCallback(() => {
    if (confirmBoxRef.current) {
      confirmBoxRef.current.prompt("Delete this session?", () => {
        deleteSession(record.id);
      });
    }
  }, [record, deleteSession]);

  const durationString = useMemo(() => {
    if (duration && isCurrent) {
      return getDurationString(duration);
    } else {
      return getDurationString(record.endTime - record.startTime);
    }
  }, [record, duration, isCurrent]);

  const slideIn = useSlideIn({
    show: record,
    duration: 333,
    x: -15,
    delay: index * 80,
  });

  return (
    <RelativeContainer
      onClick={() => selectSession(record.id)}
      style={{
        height: "auto",
        flexFlow: "row wrap",
        padding: "10px 0px",
        justifyContent: "flex-start",
        borderTop: "1px solid #cdcdcd",
        ...slideIn,
      }}
    >
      {record.startTime ? (
        <TextSpan
          style={{ fontSize: "14px", width: "auto" }}
          text={getDateString(record.startTime)}
        />
      ) : null}

      <TextSpan
        style={{
          fontSize: "14px",
          padding: "0px 5px",
          width: "auto",
          color: isCurrent ? "#C71814" : "#444",
        }}
        text={durationString}
      />
      {record.projectName ? (
        <TextSpan
          style={{ fontSize: "16px", padding: "5px 20px" }}
          text={record.projectName}
        />
      ) : null}
      {record.description ? (
        <TextSpan
          style={{ fontSize: "14px", paddingTop: "5px", paddingRight: "80px" }}
          text={record.description}
        />
      ) : null}
      <DeleteButton onDelete={handleDelete} />
      <ConfirmBox ref={confirmBoxRef} />
    </RelativeContainer>
  );
}

function ProjectSelector({ projectName, selected, toggle }) {
  return (
    <RelativeContainer
      onClick={toggle}
      style={{
        cursor: "pointer",
        width: "auto",
        height: "auto",
        margin: "5px 10px",
        fontSize: "13px",
        fontWeight: 700,
        padding: "3px 10px",
        borderRadius: 50,
        color: selected ? "#1F27C7" : "#777",
        border: `1px solid ${selected ? "#1F27C7" : "#777"}`,
        transition: "all 250ms",
      }}
    >
      {projectName}
    </RelativeContainer>
  );
}

function ClientProjectsFilter({
  projects,
  hiddenProjects,
  toggle,
  onResetProjectFilter,
  dateRange,
}) {
  return (
    <RelativeContainer
      style={{
        height: "auto",
        boxSizing: "border-box",
        background: "#fafafa",
        justifyContent: "center",
      }}
    >
      <FilterBar
        text="Filter by Projects"
        projects={projects}
        changes={hiddenProjects.length}
        onReset={onResetProjectFilter}
      />
      <RelativeContainer
        style={{ height: "auto", padding: "10px 0px", flexFlow: "row wrap" }}
      >
        {projects &&
          Object.keys(projects).map((projectName) => (
            <ProjectSelector
              key={projectName}
              toggle={() => toggle(projectName)}
              projectName={projectName}
              selected={hiddenProjects.indexOf(projectName) === -1}
            />
          ))}
      </RelativeContainer>
    </RelativeContainer>
  );
}

function DatePreset({ onClick, text, selected, hide, last }) {
  return (
    <RelativeContainer
      onClick={onClick}
      style={{
        color: selected ? "rgb(31, 39, 199)" : "#777",
        transition: "background 250ms",
        width: "auto",
        height: 40,
        padding: "0px 5px",
      }}
    >
      <span
        style={{
          fontSize: "13px",
          cursor: "pointer",
          borderRadius: "10px",
          border: `1px solid ${selected ? "rgb(31, 39, 199)" : "#777"} `,
          padding: "3px 8px",
          fontWeight: 700,
        }}
      >
        {text}
      </span>
    </RelativeContainer>
  );
}

const getMonthRange = (year, month) => {
  let begginingOfMonth = new Date(year, month);
  let endOfMonth;
  if (month === 11) {
    endOfMonth = new Date(year + 1, 0);
  } else {
    endOfMonth = new Date(year, (month + 1) % 12);
  }

  return {
    startTime: begginingOfMonth.getTime(),
    endTime: endOfMonth.getTime(),
  };
};

const getThisMonth = (minDate, maxDate) => {
  let date = new Date();
  let [month, year] = [date.getMonth(), date.getFullYear()];

  return getMonthRange(year, month);
};

const getLastMonth = (minDate, maxDate) => {
  let date = new Date();
  let [month, year] = [date.getMonth(), date.getFullYear()];

  if (month === 0) {
    return getMonthRange(year - 1, 11);
  } else {
    return getMonthRange(year, month - 1);
  }
};

const getLast30Days = () => {
  let now = Date.now();
  return { startTime: now - 1000 * 60 * 60 * 24 * 30, endTime: now };
};

const datePresets = [
  {
    text: "This Month",
    getter: getThisMonth,
  },
  {
    text: "Last Month",
    getter: getLastMonth,
  },
  {
    text: "Last 30 Days",
    getter: getLast30Days,
  },
];

function DatePresetsBar({ handlePresetSelect, selectedPresetText }) {
  const [height, setHeight] = useState(40);

  return (
    <RelativeContainer
      style={{
        height,
        transition: "height 500ms",
        justifyContent: "flex-start",
      }}
    >
      <RelativeContainer
        style={{
          height: "auto",
          flexFlow: "row wrap",
          boxSizing: "border-box",
          alignItems: "flex-start",
          //justifyContent: "flex-start",
        }}
      >
        <ResizeObserver onResize={({ height }) => setHeight(height)} />
        {datePresets.map(({ text, getter }, index) => (
          <DatePreset
            key={`date_preset_${text}`}
            selected={text === selectedPresetText}
            text={text}
            onClick={() => handlePresetSelect({ text, getter })}
            last={index === datePresets.length - 1}
          />
        ))}
      </RelativeContainer>
    </RelativeContainer>
  );
}

function DateRangeSelector({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  resetDates,
  setDatePickerActive,
  selectedPresetText,
  setSelectedPresetText,
  changes,
}) {
  const { minDate, maxDate } = useMemo(
    () =>
      startDate && endDate
        ? {
            minDate: new Date(startDate),
            maxDate: new Date(endDate),
          }
        : { minDate: null, maxDate: null },
    [startDate, endDate]
  );

  const pickerStartDate = useMemo(
    () => (startDate ? new Date(startDate) : null),
    [startDate]
  );

  const pickerEndDate = useMemo(() => (endDate ? new Date(endDate) : null), [
    endDate,
  ]);

  const handleDateClear = () => {
    setSelectedPresetText(null);
    resetDates();
  };

  const handleChangeStartDate = (newStartDate) => {
    setSelectedPresetText(null);
    setStartDate(newStartDate);
  };

  const handleChangeEndDate = (newEndDate) => {
    //add days worth of ms because date range is inclusive
    setSelectedPresetText(null);
    setEndDate(newEndDate + 1000 * 60 * 60 * 24 - 1);
  };

  const handlePresetSelect = useCallback(
    (preset) => {
      setSelectedPresetText(preset.text);
      let { startTime, endTime } = preset.getter(minDate, maxDate);
      setStartDate(startTime);
      setEndDate(endTime);
    },
    [minDate, maxDate, setStartDate, setEndDate, setSelectedPresetText]
  );

  return (
    <RelativeContainer
      style={{
        height: "auto",
        display: "block",
        alignItems: "flex-start",
        background: "#fafafa",
        //flexFlow: "row",
        borderBottom: "1px solid #cdcdcd",
        borderTop: "1px solid #cdcdcd",
        boxSizing: "border-box",
      }}
    >
      <RelativeContainer
        style={{
          height: 40,
          flexFlow: "row",
          color: "#2d2e8a",
          padding: "0px 10px",
          boxSizing: "border-box",
          borderBottom: "1px solid #eee",
          justifyContent: "flex-end",
        }}
      >
        <span>From:</span>
        <DatePicker
          onCalendarOpen={() => setDatePickerActive(true)}
          onCalendarClose={() => setDatePickerActive(false)}
          onChange={(e) => handleChangeStartDate(e.getTime())}
          clearIcon={null}
          minDetail="month"
          value={pickerStartDate}
        />
      </RelativeContainer>
      <RelativeContainer
        style={{
          height: 40,
          padding: "0px 10px",
          flexFlow: "row",
          borderBottom: "1px solid #eee",
          color: "#2d2e8a",
          boxSizing: "border-box",
          justifyContent: "flex-end",
        }}
      >
        <span>To:</span>
        <DatePicker
          onCalendarOpen={() => setDatePickerActive(true)}
          onCalendarClose={() => setDatePickerActive(false)}
          onChange={(e) => handleChangeEndDate(e.getTime())}
          minDetail="month"
          clearIcon={null}
          value={pickerEndDate}
        />
      </RelativeContainer>
      <DatePresetsBar
        changes={changes}
        handleDateClear={handleDateClear}
        selectedPresetText={selectedPresetText}
        handlePresetSelect={handlePresetSelect}
      />
    </RelativeContainer>
  );
}

function ExpandBar({ text, filteredRecords, expanded, setExpanded }) {
  const [buttonHovered, setButtonHovered] = useState(false);

  return (
    <RelativeContainer
      style={{
        height: 40,
        background: "#2d2e8a",
      }}
    >
      {text ? (
        <TextSpan
          style={{
            height: 40,
            fontSize: "14px",
            margin: "0px 5px",
            color: "#cdcdcd",
          }}
          text={text}
        />
      ) : null}

      <AbsoluteContainer
        onClick={() => setExpanded((expanded) => !expanded)}
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
        style={{
          cursor: "pointer",
          opacity: filteredRecords.length ? 1 : 0,
          color: buttonHovered ? "#fafafa" : "#ababab",
          fontSize: "13px",
          transition: "opacity 500ms",
          right: "0px",
          width: "auto",
          padding: "0px 20px",
          borderBox: "box-sizing",
        }}
      >
        {expanded ? "- COLLAPSE" : "+ VIEW RECORDS"}
      </AbsoluteContainer>
    </RelativeContainer>
  );
}

function FilterBar({ text, changes, onReset }) {
  return (
    <RelativeContainer
      style={{
        height: 30,
        background: "#2d2e8a",
        borderTop: "1px solid #2d2e8a",
        borderBottom: "1px solid #2d2e8a",
      }}
    >
      <TextSpan
        style={{
          height: 30,
          fontSize: "14px",
          margin: "0px 5px",
          color: "white",
        }}
        text={text}
      />
      <AbsoluteContainer
        onClick={onReset}
        style={{
          cursor: "pointer",
          color: "#2d2e8a",
          background: "#fafafa",
          fontSize: "15px",
          right: "0px",
          width: changes ? 80 : 0,
          transition: "width 500ms",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        RESET
      </AbsoluteContainer>
    </RelativeContainer>
  );
}

function ClientRecordsList({
  expanded,
  filteredRecords,
  selectSession,
  trackData,
}) {
  return (
    <RelativeContainer
      style={{
        height: "auto",
      }}
    >
      {expanded ? (
        filteredRecords && filteredRecords.length ? (
          filteredRecords.map((record, index) => (
            <ClientRecord
              index={index}
              selectSession={selectSession}
              trackData={trackData}
              key={`client_record_${record.startTime}`}
              record={record}
            />
          ))
        ) : (
          <TextSpan
            style={{ fontSize: "16px", color: "#777", height: 40 }}
            text="No records with the given filter"
          />
        )
      ) : null}
    </RelativeContainer>
  );
}

function GenerateInvoiceButton({ onClick, active }) {
  const [hovered, setHovered] = useState(false);

  const { height, opacity, color } = useSpring({
    height: active ? 50 : 0,
    opacity: active ? 1 : 0,
    color: hovered ? "rgb(45,46,138)" : "#777",
  });

  return (
    <animated.div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      style={{
        position: "absolute",
        bottom: "0px",
        boxSizing: "border-box",
        borderTop: "1px solid #cdcdcd",
        width: "100%",
        background: "#fafafa",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        pointerEvents: active ? 1 : 0,
        overflow: "hidden",
        fontSize: "16px",
        fontWeight: "700",
        color,
        height,
        opacity,
      }}
    >
      GENERATE INVOICE
    </animated.div>
  );
}

function ClientDetails({
  projects,
  records,
  trackData,
  active,
  selectSession,
  onResize,
  filterData,
  generateInvoice,
  setDatePickerActive,
}) {
  const [expanded, setExpanded] = useState(false);

  const {
    filteredRecords,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    selectedPresetText,
    setSelectedPresetText,
    dateChanges,
    handleToggleProject,
    hiddenProjects,
    setHiddenProjects,
    totalTime,
    handleResetDates,
  } = filterData;
  //useFilteredRecords({ projects, records });

  const slideIn = useSlideIn({ show: active });

  return (
    <RelativeContainer style={{ height: "auto", ...slideIn }}>
      <ResizeObserver onResize={onResize} />
      <FilterBar
        text="Select Date Range"
        changes={dateChanges}
        onReset={handleResetDates}
      />
      <DateRangeSelector
        changes={dateChanges}
        setDatePickerActive={setDatePickerActive}
        selectedPresetText={selectedPresetText}
        setSelectedPresetText={setSelectedPresetText}
        resetDates={handleResetDates}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
      />
      <ClientProjectsFilter
        toggle={handleToggleProject}
        projects={projects}
        hiddenProjects={hiddenProjects}
        onResetProjectFilter={() => setHiddenProjects([])}
        //dateRange={dateRange}
      />
      <ExpandBar
        text={
          totalTime
            ? `Total time: ${totalTime}`
            : "No records match the given filters"
        }
        filteredRecords={filteredRecords}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      <ClientRecordsList
        selectSession={selectSession}
        trackData={trackData}
        expanded={expanded}
        filteredRecords={filteredRecords}
      />
    </RelativeContainer>
  );
}

function ClientInfoButton({ onClick }) {
  return (
    <AbsoluteContainer
      style={{
        right: 0,
        top: 0,
        cursor: "pointer",
        width: 53,
        height: 53,
      }}
    >
      <img
        onClick={onClick}
        src={infoIcon}
        alt="info"
        title="View/Edit Client Info"
        style={{ height: 25, width: 25 }}
      />
    </AbsoluteContainer>
  );
}

export default ({
  client,
  updateClientInfo,
  generateInvoice,
  trackData,
  assignErrorCode,
  selected,
  select,
  selectSession,
  index,
}) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [clientInfoActive, setClientInfoActive] = useState(false);
  const [clientInfoHeight, setClientInfoHeight] = useState(50);

  const { records, projects, name, rate, companyName, email, id } = client;

  const clientInfo = useMemo(() => ({ name, rate, companyName, email, id }), [
    name,
    rate,
    companyName,
    email,
    id,
  ]);

  const {
    editedClientInfo,
    clientInfoError,
    clientInfoChanges,
    handleClientInfoSubmit,
    handleClientInfoReset,
    handleFieldChange,
    handleRateChange,
    handleRateBlur,
  } = useEditedClientInfo({ clientInfo, assignErrorCode, updateClientInfo });

  const filterData = useFilteredRecords({ projects, records });

  const handleGenerateInvoice = useCallback(() => {
    if (!clientInfoActive) {
      generateInvoice(clientInfo, filterData.filteredRecords);
    }
  }, [clientInfo, clientInfoActive, filterData, generateInvoice]);

  const [datePickerActive, setDatePickerActive] = useState(false);
  const generateActive = useMemo(
    () =>
      !clientInfoActive &&
      selected &&
      clientInfo &&
      filterData &&
      filterData.filteredRecords &&
      filterData.filteredRecords.length,
    [clientInfo, filterData, clientInfoActive, selected]
  );

  const containerHeight = useMemo(() => {
    let h = clientInfoActive
      ? clientInfoHeight
      : selected
      ? contentHeight + 50 + (generateActive ? 50 : 0)
      : 50;
    if (datePickerActive) {
      h = Math.max(h, 500);
    }
    return h;
  }, [
    selected,
    contentHeight,
    datePickerActive,
    clientInfoActive,
    generateActive,
    clientInfoHeight,
  ]);

  const slideIn = useSlideIn({
    show: true,
    delay: index * 100,
    y: -10,
    duration: 500,
  });

  const onClientInfoButtonClick = useCallback(() => {
    if (!clientInfoActive) {
      setClientInfoActive(true);
    } else {
      if (clientInfoChanges) {
        console.error("PROMPT HERE");
      } else {
        setClientInfoActive(false);
      }
    }
  }, [clientInfoActive, clientInfoChanges]);

  return (
    <RelativeContainer
      style={{
        height: containerHeight,
        opacity: containerHeight ? 1 : 0,
        background: "#fafafa",
        justifyContent: "flex-start",
        marginBottom: "20px",
        overflow: "hidden",
        transition: "all 500ms",
        ...slideIn,
      }}
    >
      <RelativeContainer
        style={{
          boxSizing: "border-box",
          background: "#fafafa",
          height: 50,
          display: "block",
        }}
      >
        <TextSpan
          style={{ height: 50, color: "black", fontSize: "18px" }}
          text={client.name}
        />

        <ExpandArrow expand={select} expanded={selected} />
      </RelativeContainer>
      <GenerateInvoiceButton
        active={generateActive}
        onClick={handleGenerateInvoice}
      />
      <ClientDetails
        filterData={filterData}
        active={!clientInfoActive && selected}
        setDatePickerActive={setDatePickerActive}
        selectSession={selectSession}
        trackData={trackData}
        projects={projects}
        records={records}
        onResize={({ height }) => setContentHeight(height)}
      />
      <ClientInfo
        onResize={({ height }) => setClientInfoHeight(height)}
        active={clientInfoActive}
        client={editedClientInfo}
        onReset={handleClientInfoReset}
        onSubmit={handleClientInfoSubmit}
        onCancel={onClientInfoButtonClick}
        changes={clientInfoChanges}
        handleFieldChange={handleFieldChange}
        handleRateChange={handleRateChange}
        handleRateBlur={handleRateBlur}
        error={clientInfoError}
      />

      <ClientInfoButton
        onClick={onClientInfoButtonClick}
        clientInfo={clientInfo}
      />
    </RelativeContainer>
  );
};
