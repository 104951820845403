import React, { useState, useCallback } from 'react'
import RelativeContainer from '../containers/RelativeContainer'
import AbsoluteContainer from '../containers/AbsoluteContainer'


export default ({ value, onChange, placeholder, loading, password, autocomplete, style, onBlur, onFocus }) => {

    const [focused, setFocused] = useState(false)

    const handleBlur = useCallback(() => {
        if(onBlur) onBlur()
        setFocused(false)
    }, [onBlur, setFocused])

    const handleFocus = useCallback(() => {
        if(onFocus) onFocus()
        setFocused(true)
    }, [onFocus, setFocused])


    return (
        <RelativeContainer
            style={{
                height: loading ? 0 : 50,
                overflow: 'hidden',
                transition: 'height 500ms ease'
            }}
        >
            <AbsoluteContainer
                style={{    
                    pointerEVents: 'none',
                    zIndex: 1,
                    top: '0px',
                    height: 50,
                    left: '0px',
                    background: 'rgba(255,255,255,0.5)',
                    width: focused ? '100%' : '0%',
                    transition: 'width 500ms ease'
                }}
            />
            <input
                autoComplete={autocomplete}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={value || ''}
                type={password ? 'password' : 'text'}
                onChange={e => onChange(e.target.value)}
                placeholder={placeholder}
                style={{
                    zIndex: 1,
                    border: 'none',
                    width: '100%',
                    boxSizing: 'border-box',
                    background: 'none',
                    height: 50,
                    padding: '10px 20px',
                    borderBottom: '1px solid #cdcdcd',
                    fontSize: '18px',
                    fontFamily: 'Muli',
                    ...style
                }}
            />

        </RelativeContainer>

    )
}