import { useEffect, useState, useMemo } from "react";

function useSlideIn({ delay = 0, duration = 250, x = 0, y = 0, show = true }) {
  const [transform, setTransform] = useState(
    `translate(${x || 0}px, ${y || 0}px)`
  );
  const [opacity, setOpacity] = useState(0);
  const transition = useMemo(() => `all ${duration}ms ease`, [
    duration,
  ]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setTransform(`translate(0px, 0px)`);
        setOpacity(1);
      }, delay);
    } else {
      setTransform(`translate(${x || 0}px, ${y || 0}px)`);
      setOpacity(0);
    }
  }, [x, y, delay, show]);

  return {
    transform,
    opacity,
    transition,
    pointerEvents: show ? "auto" : "none",
  };
}

export default useSlideIn;
