const sortBy = (data, fieldName, ascending = false) => {
    if (ascending) {
        return data.sort((a, b) => a[fieldName] - b[fieldName])
    }
    else {
        return data.sort((a, b) => b[fieldName] - a[fieldName])
    }
}

const toList = object => {
    let list = []
    let keys = Object.keys(object)
    for (let i in keys) {
        let key = keys[i]
        list.push({ name: key, ...object[key] })
    }

    return list
}

const sortObjectBy = (object, fieldName, ascending = false) => {
    return sortBy(toList(object), fieldName, ascending)
}

export {sortBy, toList, sortObjectBy}