import React, { useState } from 'react'

import RelativeContainer from '../containers/RelativeContainer'

import MenuLink from './MenuLink'
import AbsoluteContainer from '../containers/AbsoluteContainer'

import accountIcon from '../../icons/account.svg'

export default ({ pages, navigateTo, selectedIndex, height, user, activateAccountPage, accountPageActive }) => {

    const menuItemWidth = `${75 / pages.length}%`

    const [accountHovered, setAccountHovered] = useState(false)

    return (
        <RelativeContainer
            style={{
                flexFlow: 'row',
                justifyContent: 'flex-start'
                //width: '33%'
            }}
        >
            {
                pages.map((page, index) =>
                    page.menu ?
                        <MenuLink
                            active={!accountPageActive}
                            onClick={() => navigateTo(index)}
                            selected={index === selectedIndex}
                            key={page.path + '_link'}
                            width={menuItemWidth}
                            title={page.name}
                            to={page.path}
                        />
                        : null
                )
            }
            <AbsoluteContainer
                onClick={activateAccountPage}
                onMouseEnter={() => setAccountHovered(true)}
                onMouseLeave={() => setAccountHovered(false)}
                style={{
                    cursor: 'pointer',
                    flexFlow: 'row',
                    pointerEvents: 'auto',
                    width: 'auto',
                    right: '0px',
                    color: '#cdcdcd',
                    opacity: accountHovered ? 1 : 0.8
                }}
            >
                {user ? user.email : 'SIGN IN'}
                <img src={accountIcon} alt='account' style={{ height: '50%', margin: '0px 10px', filter: `invert(100%)` }} />
            </AbsoluteContainer>
        </RelativeContainer>
    )
}