import React, { useState, useRef, useMemo, useCallback } from "react";
import RelativeContainer from "../containers/RelativeContainer";
import ResizeObserver from "react-resize-observer";
import TextSpan from "../text/TextSpan";
import RecordButton from "./RecordButton";
import DeleteButton from "./DeleteButton";
import ConfirmBox from "../inputs/ConfirmBox";

import { getDurationString, getDateString } from "../../utils/timeUtils";
import useSlideIn from "../../hooks/useSlideIn";

export default function SessionItem({
  currentSession,
  duration,
  session,
  selectSession,
  deleteSession,
  startRecord,
  stopRecord,
}) {
  const [height, setHeight] = useState(0);

  const dateString = useMemo(
    () => (session ? getDateString(session.startTime, session.endTime) : ""),
    [session]
  );

  const confirmBoxRef = useRef();

  const durationString = useMemo(() => {
    if (duration && currentSession) {
      return getDurationString(duration);
    } else {
      return getDurationString(session.endTime - session.startTime);
    }
  }, [session, duration, currentSession]);

  const handleDelete = useCallback(() => {
    if (confirmBoxRef.current) {
      confirmBoxRef.current.prompt("Delete this session?", () => {
        deleteSession(session.id);
      });
    }
  }, [session, deleteSession]);

  const handleStartRecord = useCallback(() => {
    if (session && startRecord) {
      let newItem = { ...session };
      delete newItem.id;
      delete newItem.startTime;
      delete newItem.endTime;
      startRecord(newItem);
    }
  }, [session, startRecord]);

  const slideIn = useSlideIn({ show: session, duration: 1000 });

  const client = useMemo(() => (session ? session.client : null), [session]);

  return (
    <RelativeContainer
      style={{
        height,
        //opacity: mounted ? 1 : 0,
        paddingBottom: "20px",
        overflow: "hidden",
        transition: "all 500ms",
        ...slideIn,
      }}
    >
      <RelativeContainer
        onClick={() => selectSession(session.id)}
        style={{
          boxSizing: "border-box",
          padding: "20px 0px",
          background: "#fafafa",
          height: "auto",
          display: "block",
          borderBottom: "1px solid #cdcdcd",
        }}
      >
        <ResizeObserver onResize={({ height }) => setHeight(height)} />
        <TextSpan
          style={{ fontSize: "14px", padding: "5px 20px" }}
          text={dateString}
        />
        <TextSpan
          style={{
            fontSize: "14px",
            borderBottom: "1px solid #cdcdcd",
            color: currentSession ? "#C73114" : "#444",
            height: durationString ? 30 : 0,
            transition: "height 500ms",
            overflow: "hidden",
          }}
          text={durationString}
        />
        {client ? (
          <TextSpan style={{ padding: "10px 20px" }} text={client.name} />
        ) : null}
        {session.projectName ? (
          <TextSpan
            style={{ fontSize: "16px", padding: "5px 20px" }}
            text={session.projectName}
          />
        ) : null}
        {session.description ? (
          <TextSpan
            style={{
              fontSize: "14px",
              paddingTop: "5px",
              paddingRight: "80px",
            }}
            text={session.description}
          />
        ) : null}
        <RecordButton
          style={{ right: "4%", bottom: "10%" }}
          size={40}
          recording={currentSession}
          stopRecord={stopRecord}
          startRecord={handleStartRecord}
        />
        <DeleteButton onDelete={handleDelete} />
        <ConfirmBox ref={confirmBoxRef} />
      </RelativeContainer>
    </RelativeContainer>
  );
}
