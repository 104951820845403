import React from 'react'

import RelativeContainer from '../containers/RelativeContainer'
import AbsoluteContainer from '../containers/AbsoluteContainer'

function MenuLink({ title, to, onClick, width, selected, active }) {

    return (
        <RelativeContainer
            style={{
                width: 'auto',
                padding: '0px 10px',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                lineHeight: '30px',
                alignItems: 'center'
            }}
        >
            <RelativeContainer
                onClick={onClick}
                style={{
                    cursor: 'pointer',
                    width: 'auto',
                    color: 'white'
                }}
            >
                {title.toUpperCase()}
            </RelativeContainer>
            <AbsoluteContainer
                style={{
                    pointerEvents: 'none',
                    opacity: selected ? 1 : 0,
                    height: 25,
                    width: 'auto',
                    color: 'rgba(255,255,255,1)',
                    borderBottom: '1px solid white',
                    transition: 'opacity 250ms ease'
                }}
            >
                {title.toUpperCase()}
            </AbsoluteContainer>
        </RelativeContainer>

    )
}

export default MenuLink