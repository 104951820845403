import React from "react";
import { useSpring, animated } from "react-spring";

export default function SlidingButton({ onClick, active, text, up = true }) {
  const props = useSpring({
    y: active ? 0 : up ? -10 : 10,
    opacity: active ? 1 : 0,
    filter: `blur(${active ? 0 : 5}px)`,
  });

  return (
    <animated.span
      onClick={onClick}
      style={{
        position: "absolute",
        cursor: "pointer",
        pointerEvents: active ? "auto" : "none",
        transform: props.y.interpolate((y) => `translate(0px, ${y}px)`),
        ...props,
      }}
    >
      {text}
    </animated.span>
  );
}
