import React, { useEffect, useMemo, useState, useCallback } from "react";
import RelativeContainer from "../containers/RelativeContainer";
import ClientList from "../clients/ClientList";
import { cloneDeep, isEqual } from "lodash";
import AbsoluteContainer from "../containers/AbsoluteContainer";
import useSlideIn from "../../hooks/useSlideIn";
import useEditedClientInfo from "../clients/useEditedClientInfo";
import ClientInfo from "../clients/ClientInfo";

function InvoicePrep({
  invoiceData,
  assignErrorCode,
  deactivate,
  contentWidth,
  bodyHeight,
  onSubmit,
}) {
  // const handleSubmit = useCallback(
  //   (fields) => {
  //     onSubmit({
  //       name: fields[0],
  //       rate: fields[1],
  //       email: fields[2],
  //       companyName: fields[3],
  //     });
  //   },
  //   [onSubmit]
  // );

  // const options = useMemo(
  //   () => [
  //     {
  //       title: "Confirm Client Data",
  //       fields: [
  //         {
  //           placeholder: "Client Name",
  //           name: "name",
  //           autocomplete: "new-password",f
  //         },
  //         {
  //           placeholder: "Default Rate",
  //           name: "rate",
  //           autocomplete: "new-password",
  //         },
  //         {
  //           placeholder: "Client Email",
  //           name: "email",
  //           autocomplete: "new-password",
  //         },
  //         {
  //           placeholder: "Company Name",
  //           name: "companyName",
  //           autocomplete: "new-password",
  //           optional: true,
  //         },
  //       ],
  //       callback: handleSubmit,
  //     },
  //   ],
  //   [handleSubmit]
  // );

  // const initValues = useMemo(() => {
  //   if (invoiceData) {
  //     if (invoiceData.client) {
  //       let client = invoiceData.client;
  //       return [[client.name, client.rate, client.email, client.companyName]];
  //     }
  //   } else {
  //     return null;
  //   }
  // }, [invoiceData]);

  const {
    editedClientInfo,
    clientInfoError,
    clientInfoChanges,
    handleClientInfoSubmit,
    handleClientInfoReset,
    handleFieldChange,
    handleRateChange,
    handleRateBlur,
  } = useEditedClientInfo({
    clientInfo: invoiceData ? invoiceData.client : null,
    assignErrorCode,
    alwaysSave: true,
    updateClientInfo: onSubmit,
  });

  const invoicePrepProps = useSlideIn({ show: invoiceData });
  return (
    <AbsoluteContainer
      style={{
        top: 0,
        height: bodyHeight,
        width: contentWidth,
        ...invoicePrepProps,
      }}
    >
      <ClientInfo
        onResize={() => {}}
        active={invoiceData}
        client={editedClientInfo}
        onReset={handleClientInfoReset}
        onSubmit={handleClientInfoSubmit}
        onCancel={deactivate}
        changes={clientInfoChanges}
        title="CONFIRM CLIENT INFO"
        alwaysSave={true}
        saveText="CONFIRM"
        handleFieldChange={handleFieldChange}
        handleRateChange={handleRateChange}
        handleRateBlur={handleRateBlur}
        error={clientInfoError}
      />
    </AbsoluteContainer>
  );
}

export default ({
  user,
  clients,
  trackData,
  assignErrorCode,
  updateClientInfo,
  selectSession,
  contentWidth,
  generateInvoice,
  bodyHeight,
  setMessage,
}) => {
  const [invoiceData, setInvoiceData] = useState(false);

  useEffect(() => {
    if (!user) {
      setMessage("Sign in to track time");
    } else {
      if (trackData.track && !trackData.track.length) {
        setMessage('No sessions recorded. Press the record button to create a session.')
      } else {
        setMessage("");
      }
    }

    return () => setMessage("");
  }, [user, setMessage, trackData]);

  const clientsWithRecordData = useMemo(() => {
    if (trackData && trackData.track && clients) {
      let auxClients = cloneDeep(clients);
      let records = trackData.track;
      for (let i in records) {
        let record = records[i];
        if (record.clientId) {
          for (let j in auxClients) {
            let client = auxClients[j];
            if (client.id === record.clientId) {
              if (record.projectName) {
                if (!client.projects) client.projects = {};
                if (!client.projects[record.projectName])
                  client.projects[record.projectName] = { records: [] };
                client.projects[record.projectName].records.push(record);
              } else {
                if (!auxClients[j].records) auxClients[j].records = [];
                auxClients[j].records.push(record);
              }
              break;
            }
          }
        } else {
          console.error("NO CLIENT ID ON RECORD");
        }
      }
      return auxClients;
    } else {
      return null;
    }
  }, [clients, trackData]);

  const handleGenerateInvoice = (client, records) => {
    setInvoiceData({ client, records });
  };

  const updateClientIfNecessary = useCallback(
    (fields, invoiceData, callback) => {
      let prevClient = cloneDeep(invoiceData.client);
      delete prevClient.id;
      if (isEqual(prevClient, fields)) {
        console.log("IS EQUAL");
        callback({ invoiceData });
      } else {
        let newClient = { ...fields, id: invoiceData.client.id };
        updateClientInfo(newClient, ({ success, error }) => {
          if (error) {
            console.log(error);
            callback({ error });
          } else {
            console.log(invoiceData);
            callback({ invoiceData: { ...invoiceData, client: newClient } });
          }
        });
      }
    },
    [updateClientInfo]
  );

  const handleInvoicePrepSubmit = useCallback(
    (fields) => {
      if (invoiceData) {
        console.log(fields, invoiceData);
        if (fields) {
          updateClientIfNecessary(
            fields,
            invoiceData,
            ({ invoiceData, error }) => {
              console.log(invoiceData);
              if (invoiceData) {
                generateInvoice(invoiceData);
              } else if (error) {
              }
            }
          );
        }
      }
    },
    [invoiceData, generateInvoice, updateClientIfNecessary]
  );

  return (
    <RelativeContainer
      style={{
        height: "auto",
        justifyContent: "flex-start",
      }}
    >
      <ClientList
        active={!invoiceData}
        selectSession={selectSession}
        assignErrorCode={assignErrorCode}
        updateClientInfo={updateClientInfo}
        generateInvoice={handleGenerateInvoice}
        width={contentWidth}
        trackData={trackData}
        clients={clientsWithRecordData}
      />
      <InvoicePrep
        contentWidth={contentWidth}
        onSubmit={handleInvoicePrepSubmit}
        assignErrorCode={assignErrorCode}
        deactivate={() => setInvoiceData(null)}
        invoiceData={invoiceData}
        bodyHeight={bodyHeight}
      />
    </RelativeContainer>
  );
};
