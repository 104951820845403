import React, { useState, useMemo, useEffect, useRef } from "react"
import AbsoluteContainer from "../containers/AbsoluteContainer"

export default ({ message, onClick }) => {

    const height = useMemo(() => message ? 60 : 0, [message])

    const [prevMessage, setPrevMessage] = useState('')

    const timeout = useRef()

    useEffect(() => {
        if(message){
            if(timeout.current){
                clearTimeout(timeout.current)
            }
            setPrevMessage(message)
        }
        else{
            timeout.current = setTimeout(() => setPrevMessage(''), 2000)
        }

        return () => {
            if(timeout.current){
                clearTimeout(timeout.current)
            }
        }
    }, [message])


    return (
        <AbsoluteContainer
            onClick={onClick}
            style={{
                position: 'sticky',
                cursor: 'pointer',
                overflow: 'hidden',
                boxSizing: 'border-box',
                padding: '0px 20px',
                textAlign: 'center',
                top: 0,
                zIndex: 1,
                opacity: message ? 1 : 0,
                transition: 'all 500ms',
                height,
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Muli'
            }}
        >
            {
                prevMessage
            }
        </AbsoluteContainer>
    )
}