import React from "react";
import { animated, useSpring } from "react-spring";
import useDocDimensions from "../../hooks/useDocDimensions";
import { useDrag } from "react-use-gesture";


const getProgress = (x, y, width, height) => {
    const cx = width / 2;
    const cy = height / 2;
    const dx = cx - x;
    const dy = y - cy;
    return (Math.atan2(dx, dy) + Math.PI) / (2 * Math.PI);
  };

export default function ProgressCircle({
  radius,
  color,
  active,
  progress,
  onChange,
}) {
  const { height, width } = useDocDimensions();
  const circumference = radius * 2 * Math.PI;

  const { progressBar } = useSpring({
    progressBar: active
      ? (1 - (isNaN(progress) ? 0 : progress)) * radius * 2 * Math.PI
      : radius * 2 * Math.PI,
  });

  const bind = useDrag(({ down, xy: [x, y] }) => {
    let progress = getProgress(x, y, width, height);

    requestAnimationFrame(() => {
      onChange(progress, down);
    });
  });

  return (
    <>
      <animated.circle
        r={radius}
        style={{ transform: "rotate(-90deg)" }}
        strokeDasharray={radius * 2 * Math.PI}
        stroke={color}
        strokeWidth="15"
        cx={0}
        fill="none"
        cy={0}
        strokeDashoffset={progressBar}
      />
      <animated.circle
        {...bind()}
        r={radius}
        style={{
          transform: "rotate(-90deg)",
          pointerEvents: active ? "auto" : "none",
          cursor: "pointer",
        }}
        strokeDasharray={`${1} ${circumference - 1}`}
        strokeLinecap="round"
        stroke={color}
        strokeWidth={35}
        cx={0}
        fill="none"
        cy={0}
        strokeDashoffset={progressBar}
      />
    </>
  );
}
