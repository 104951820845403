import React, { useEffect, useRef } from "react";
import RelativeContainer from "../containers/RelativeContainer";
import TimePicker from "../time-picker/TimePicker";

export default ({ selected, bodyHeight, contentWidth }) => {
  const clockEditRef = useRef();

  useEffect(() => {
    const foreverClock = () =>
      setTimeout(() => {
        if (clockEditRef.current) {
          clockEditRef.current.promptTime(
            null,
            ({ minutes, hours }) => {
              foreverClock();
            },
            ({ minutes, hours }) => {
              foreverClock();
            }
          );
        }
      }, 1000);
    foreverClock();
  }, []);

  return (
    <RelativeContainer
      style={{
        justifyContent: "flex-start",
        height: bodyHeight
      }}
    >
      <RelativeContainer
        style={{
          height: bodyHeight,
          width: contentWidth,
          justifyContent: "flex-start",
        }}
      >
        <TimePicker ref={clockEditRef} />
      </RelativeContainer>
    </RelativeContainer>
  );
};
