import React from "react";

const r = 225;
const circ = r * 2 * Math.PI;
const hour = circ / 12;
const dash = hour / 4;
const gap = (3 * hour) / 4;

const min = circ / 60;
const minDash = min / 4;
const minGap = (3 * min) / 4;

const offset = (360 / 12 / 4 / 2) * -1;
const minOffset = (offset / 60) * 12;

export default function ClockBackground() {
  return (
    <>
      <circle
        r="240"
        fill="black"
        strokeWidth="30"
        style={{ opacity: 0.5 }}
        cx={0}
        cy={0}
      />
      <circle
        r="225"
        stroke="#efefef"
        strokeWidth="30"
        style={{ transform: `rotate(${offset}deg)` }}
        strokeDasharray={`${dash} ${gap}`}
        cx={0}
        fill="none"
        cy={0}
      />
      <circle
        r="225"
        stroke="#efefef"
        strokeWidth="20"
        style={{ transform: `rotate(${minOffset}deg)` }}
        strokeDasharray={`${minDash} ${minGap}`}
        cx={0}
        fill="none"
        cy={0}
      />
    </>
  );
}
