import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

import firebaseConfig from "./firebasecfg";
import { sortBy } from "../utils/objectUtils";
import useOnSnapshot from "./useOnSnapshot";
import { cloneDeep } from "lodash";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

const db = firebase.firestore();

export default ({ user, clients }) => {
  const { data: trackNoClients } = useOnSnapshot(user, db, "track");

  const track = useMemo(() => {
    if (trackNoClients && clients) {
      let auxTrack = cloneDeep(trackNoClients);
      for (let i in auxTrack) {
        let record = auxTrack[i];
        if (record.clientId) {
          for (let i in clients) {
            if (clients[i].id === record.clientId) {
              record.client = clients[i];
              break;
            }
          }
        } else {
          console.error("NO CLIENT ID ON RECORD");
        }
      }
      return auxTrack;
    } else {
      return null;
    }
  }, [trackNoClients, clients]);

  const [duration, setDuration] = useState(0);

  const sortedData = useMemo(() => {
    return track ? sortBy(track, "startTime") : null;
  }, [track]);

  const durationTimer = useRef();

  const currentSession = useMemo(() => {
    if (user && track && user.currentSession) {
      let records = track.filter(
        (session) => session.id === user.currentSession
      );
      if (records.length) {
        return records[0];
      }
    }
    return null;
  }, [user, track]);

  useEffect(() => {
    if (durationTimer.current) {
      clearInterval(durationTimer.current);
      durationTimer.current = null;
    }

    if (currentSession) {
      durationTimer.current = setInterval(
        () => setDuration(Date.now() - currentSession.startTime),
        1000
      );
    } else {
      setDuration(0);
    }

    return () => {
      if (durationTimer.current) {
        clearInterval(durationTimer.current);
        durationTimer.current = null;
      }
    };
  }, [currentSession]);

  const stopRecording = useCallback(
    (callback) => {
      const userRef = db.collection("users").doc(user.id);
      if (user.currentSession) {
        let now = Date.now();
        userRef
          .collection("track")
          .doc(user.currentSession)
          .update({ endTime: now })
          .then(() => {
            userRef
              .update({
                currentSession: firebase.firestore.FieldValue.delete(),
              })
              .then(() => {
                if (callback) callback({ success: true });
              })
              .catch((err) => {
                if (callback) callback({ error: err.message });
                console.log(err);
              });
          })
          .catch((err) => {
            if (callback) callback({ error: err.message });
            console.log(err);
          });
      } else {
        callback({ error: "No user session" });
      }
    },
    [user]
  );

  const createClientIfNecessary = (userRef, data, callback) => {
    let clientsRef = userRef.collection("clients");
    if (data.clientId) {
      clientsRef
        .doc(data.clientId)
        .get()
        .then((res) => {
          if (data.clientName) {
            if (res.data.name === data.clientName) {
              callback({ clientId: data.clientId });
            } else {
              console.log(data);
              let newClientRef = clientsRef.doc();
              newClientRef
                .set({ name: data.clientName })
                .then(() => {
                  callback({ clientId: newClientRef.id });
                })
                .catch((err) => {
                  callback({ error: err });
                });
            }
          } else {
            callback({ clientId: data.clientId });
          }
        })
        .catch((err) => {
          callback({ error: err.message });
        });
    } else if (data.clientName) {
      clientsRef
        .where("name", "==", data.clientName)
        .get()
        .then((res) => {
          if (res.docs && res.docs.length) {
            let clientId = res.docs[0].id;
            callback({ clientId });
          } else {
            let clientRef = userRef.collection("clients").doc();
            console.log(data);
            clientRef
              .set({ name: data.clientName })
              .then(() => {
                let clientId = clientRef.id;
                callback({ clientId });
              })
              .catch((err) => {
                console.log(err);
                callback({ error: "error setting new client in db" });
              });
          }
        })
        .catch((err) => {
          console.log(err);
          callback({ error: "error getting client by nams" });
        });
    } else {
      callback({ error: "error" });
    }
  };

  const saveSessionData = useCallback(
    (data, callback) => {
      if (user && user.id && data) {
        const userRef = db.collection("users").doc(user.id);
        createClientIfNecessary(userRef, data, ({ clientId }) => {
          if (clientId) {
            let dataId = data.id;
            delete data.id;

            data.clientId = clientId;
            let sessionRef = userRef.collection("track").doc(dataId);

            sessionRef
              .update(data)
              .then(() => {
                if (callback) callback({ success: true });
              })
              .catch((e) => {
                console.log(e);
                if (callback) callback({ error: e.message });
              });
          } else {
            if (callback)
              callback({
                error: "INTERNAL ERROR: Client not found by ID or created",
              });
          }
        });
      } else {
        if (callback)
          callback({
            error: "INTERNAL ERROR: error getting user or session data",
          });
      }
    },
    [user]
  );

  const startRecording = useCallback(
    (data, callback) => {
      const userRef = db.collection("users").doc(user.id);

      if (user && user.id) {
        stopRecording(() => {
          let sessionRef = userRef.collection("track").doc();
          let startTime = Date.now();

          createClientIfNecessary(userRef, data, ({ clientId, error }) => {
            if (error) {
              if (callback) callback({ error });
            } else {
              if (clientId) {
                delete data.clientName;
                delete data.client;
                data.clientId = clientId;
                sessionRef
                  .set({ ...data, startTime })
                  .then(() => {
                    userRef
                      .update({ currentSession: sessionRef.id })
                      .then(() => {
                        if (callback) callback({ success: true });
                      })
                      .catch((e) => {
                        console.log(e);
                        if (callback) callback({ error: e.message });
                      });
                  })
                  .catch((e) => {
                    console.log(e);
                    if (callback) callback({ error: e.message });
                  });
              } else {
                if (callback)
                  callback({ error: "No clientId returned from function" });
              }
            }
          });
        });
      } else {
        console.error("ERROR: attempted to create recording with no user id");
        if (callback)
          callback({
            error: "ERROR: attempted to create recording with no user id",
          });
      }
    },
    [user, stopRecording]
  );

  const deleteSession = useCallback(
    (sessionId) => {
      if (user && user.id) {
        const userRef = db.collection("users").doc(user.id);
        if (user.currentSession === sessionId) {
          userRef
            .update({ currentSession: firebase.firestore.FieldValue.delete() })
            .then(() => {
              userRef
                .collection("track")
                .doc(sessionId)
                .delete()
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          userRef
            .collection("track")
            .doc(sessionId)
            .delete()
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    [user]
  );

  return {
    track: sortedData,
    duration,
    currentSession,
    startRecording,
    saveSessionData,
    stopRecording,
    deleteSession,
  };
};
