
export default () => {
  let errorCodeCount = 0;

  const assignErrorCode = () => {
    errorCodeCount++;
    return `INTERNAL ERROR CODE ${errorCodeCount - 1} - please report ` 
  };

  return { assignErrorCode };
};
