import React, { useCallback } from 'react'
import StopButton from './StopButton'



function BigCircle() {
    let circle = "M 40 320 Q 40 40 320 40 Q 600 40 600 320 Q 600 600 320 600 Q 40 600 40 320 Z"
    return (
        <path
            d={circle}
            fill='white'
            strokeWidth={0}
            stroke="none"
        //stroke={recording ? '#56115E' : '#8A0000'}
        />
    )
}


export default function RecordButton({ startRecord, stopRecord, recording, size = 50, data, style, overlay }) {

    const handleClick = useCallback(e => {
        e.stopPropagation()
        if (recording) {
            stopRecord()
        }
        else {
            startRecord()
        }
    }, [recording, stopRecord, startRecord])

    return (
        <svg
            onClick={handleClick}
            shapeRendering="geometricPrecision"
            viewBox="0 0 640 640"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                position: overlay ? 'fixed' : 'absolute',
                zIndex: overlay ? 3 : 0,
                cursor: 'pointer',
                pointerEvents: 'auto',
                filter: `drop-shadow(0 0 ${size / 12}px #333)`,
                height: size,
                width: size,
                bottom: 25,
                right: 15,
                ...style
            }}
        >
            <BigCircle
                diameter={50}
                strokeWidth={0}
                color={'#fafafa'}
                stroke='#C73114'
            />

            {/* <Circle
                color={recording ? '#56115E' : '#8A0E0E'}
                diameter={50}
                scale={recording ? 0 : 1}
            /> */}
            <StopButton recording={recording} length={47} />

        </svg>
    )
}
