import { useState, useCallback, useMemo } from "react"

export default (({ trackData }) => {
    const [selectedSessionId, setSelectedSessionId] = useState(null)


    const handleDialogSubmit = useCallback((fields, callback) => {

        if (trackData) {
            if (selectedSessionId && (selectedSessionId !== 'new')) {
                trackData.saveSessionData(({ ...fields, id: selectedSessionId }), ({ error, success }) => {
                    if (error) {
                        console.log(error)
                    }
                    else {
                        setSelectedSessionId(null)
                    }
                    callback({ error, success })
                })
            }
            else {
                trackData.startRecording(fields, ({ error, success }) => {
                    if (error) {
                        //setError(error)
                        callback({ error })
                    }
                    else {
                        setSelectedSessionId(null)
                        callback({ success })
                    }
                    
                })
            }
        }

    }, [trackData, selectedSessionId])

    const getSessionById = useCallback(id => {

        if (id === 'new') {
            return ({
                startTime: Date.now()
            })
        }

        let session = null
        if (trackData && trackData.track) {
            for (let i in trackData.track) {
                if (trackData.track[i].id === id) {
                    session = trackData.track[i]
                    break
                }
            }
        }

        return session
    }, [trackData])

    const deactivateDialog = () => {
        setSelectedSessionId(null)
    }

    const promptNewSession = () => {
        setSelectedSessionId('new')
    }

    const selectSession = id => setSelectedSessionId(id)

    const selectedSession = useMemo(() => {
        if (trackData && selectedSessionId) {
            return getSessionById(selectedSessionId)
        }
        return null
    }, [trackData, selectedSessionId, getSessionById])

    return { selectedSession, selectSession, handleDialogSubmit, promptNewSession, deactivateDialog }
})
