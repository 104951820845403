import React, { useMemo } from "react";
import RelativeContainer from "../containers/RelativeContainer";
import AbsoluteContainer from "../containers/AbsoluteContainer";
import RecordButton from "../track/RecordButton";
import TextSpan from "../text/TextSpan";
import { getShortDurationString } from "../../utils/timeUtils";
import useSlideIn from "../../hooks/useSlideIn";

export default ({ trackData, user, promptNewSession }) => {
  const currentSession = useMemo(
    () => (user ? (trackData ? trackData.currentSession : null) : null),
    [trackData, user]
  );

  const slideIn = useSlideIn({ show: currentSession, y: 15, duration: 500 });

  return (
    <AbsoluteContainer
      style={{
        pointerEvents: "none",
        position: "fixed",
        //background: 'white',
        bottom: 0,
        color: "white",
        justifyContent: "flex-end",
        overflow: "hidden",
      }}
    >
      {
        <RelativeContainer
          style={{
            background:
              "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0.9) 100%)",
            justifyContent: "flex-end",
            paddingBottom: "10px",
            height: 80,
            ...slideIn,
          }}
        >
          {currentSession &&
          currentSession.client &&
          currentSession.client.name ? (
            <TextSpan
              style={{
                fontWeight: 700,
                padding: "0px 20px",
                filter: "drop-shadow(0 0 3px #000)",
              }}
              text={currentSession.client.name}
            />
          ) : null}
          {currentSession && currentSession.projectName ? (
            <TextSpan
              text={currentSession.projectName}
              style={{
                padding: "0px 20px",
                fontSize: "14px",
                filter: "drop-shadow(0 0 3px #000)",
              }}
            />
          ) : null}
        </RelativeContainer>
      }

      <RecordButton
        startRecord={promptNewSession}
        style={{
          transform: user ? slideIn.transform : "translate(0px, 50px)",
          opacity: user ? 1 : 0,
          pointerEvents: user ? "auto" : "none",
          transition: "all 500ms",
        }}
        stopRecord={trackData ? trackData.stopRecording : () => {}}
        size={50}
        recording={currentSession}
      />
      <TextSpan
        style={{
          position: "absolute",
          right: 0,
          fontWeight: 700,
          filter: "drop-shadow(0 0 3px #000)",
          bottom: 5,
          fontSize: "13px",
          textAlign: "center",
          alignItems: "center",
          padding: "0px 5px",
          width: 80,
          color: "white",
          ...slideIn,
          opacity: trackData && trackData.duration ? 1 : 0,
        }}
        text={
          trackData && trackData.duration
            ? getShortDurationString(trackData.duration)
            : ""
        }
      />
    </AbsoluteContainer>
  );
};
