import React from 'react'
import {animated, useSpring, config} from 'react-spring'

export default function StopButton({ recording, length }) {

    let roundedSquare = 'M 180 320 C 180 180 180 180 320 180 C 460 180 460 180 460 320 C 460 460 460 460 320 460 C 180 460 180 460 180 320 Z'
    
    let smallCircle = 'M 160 320 C 160 220 220 160 320 160 C 420 160 480 220 480 320 C 480 420 420 480 320 480 C 220 480 160 420 160 320 Z'
    //let smallCircle = 'M 120 320 Q 120 120 320 120 Q 520 120 520 320 Q 520 520 320 520 Q 120 520 120 320 Z'

    const {path, rotate, color} = useSpring({path: recording ? roundedSquare : smallCircle, rotate: recording ? 90 : 0, color: recording ?  '#56115E' : '#8A0000', config: config.gentle, animationDuration: 2000})

    return (
        <animated.path
            d={path}
            style={{
                transform: rotate.interpolate(rotate => `rotate(${rotate}deg) scale(1.2)`),
                transformOrigin: 'center'
            }}
            fill='none'
            stroke={color}
            strokeWidth={30}
            // style={{
            //     transform: `rotate(${recording ? 0 : 30}deg) scale(${recording ? 1 : 0})`,
            //     transition: 'all 500ms',
            // }}
        />
    )
}

