import React, { useState, useCallback } from 'react'
import RelativeContainer from '../containers/RelativeContainer'
import AbsoluteContainer from '../containers/AbsoluteContainer'

import loadingIcon from './dna.svg'


export default ({ onClick, text, loading, color, style, containerStyle }) => {

    const [hovered, setHovered] = useState(false)

    const handleHover = useCallback((value) => {
        if(loading) {
            setHovered(false)
        }
        else{
            setHovered(value)
        }
    }, [loading])

    return (
        <RelativeContainer
            style={{
                display: 'inline-block',
                height: 50,
                //border: `1px solid ${color || 'white'}`,
                background: loading ? '#444' : '#fafafa',
                margin: '10px 0px',
                transition: 'background 500ms ease',
                ...containerStyle
            }}
        >
            <AbsoluteContainer
                style={{
                    zIndex: '0',
                    left: '0px',
                    background: 'rgba(255,255,255,0.5)',
                    width: hovered ? '100%' : '0%',
                }}
            />
            <AbsoluteContainer
                style={{
                    top: '0px',
                    left: '0px',
                    opacity: loading ? 1 : 0,
                    transition: 'opacity 250ms ease'
                }}
            >
                <img alt='loading' src={loadingIcon} style={{height: '100%', width: '100%'}} />
            </AbsoluteContainer>
            <RelativeContainer
                onClick={onClick}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                style={{
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontFamily: 'Muli',
                    opacity: loading ? 0 : 1,
                    transition: 'opacity 250ms ease',
                    color: color || 'black',
                    ...style
                }}
            >
                {text}
            </RelativeContainer>
        </RelativeContainer>
    )
}
