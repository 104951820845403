import React from 'react'
import AbsoluteContainer from '../containers/AbsoluteContainer'
import RelativeContainer from '../containers/RelativeContainer'

import accountIcon from '../../icons/account.svg'
import FormSelector from '../inputs/FormSelector'
import CloseButton from '../inputs/CloseButton'
import Button from '../inputs/Button'


function SignInBox({ user, active, signIn, registerAccount, deactivate, width = '100%' }) {

    const options = [
        {
            title: 'SIGN IN',
            fields: [
                {
                    placeholder: 'Email',
                    name: 'email',
                    autocomplete: 'email'
                },
                {
                    placeholder: 'Password',
                    autocomplete: 'current-password',
                    name: 'password',
                    type: 'password'
                }
            ],
            callback: signIn
        },
        {
            title: 'REGISTER ACCOUNT',
            fields: [
                {
                    name: 'email',
                    placeholder: 'Email'
                },
                {
                    name: 'password',
                    placeholder: 'Password',
                    autocomplete: 'new-password',
                    type: 'password'
                },
                {
                    name: 'confirmPassword',
                    placeholder: 'Confirm Password',
                    autocomplete: 'new-password',
                    type: 'password'
                }
            ],
            callback: registerAccount
        }]

    return (
        <FormSelector width={width} deactivate={deactivate} active={active} options={options} />
    )

}


function AccountBox({ user, signOut, deactivate, width = '100%', active }) {

    return (
        <RelativeContainer
            style={{
                height: 'auto',
                width,
                background: '#fafafa',
                opacity: active ? 1 : 0,
                pointerEvents: active ? 'auto' : 'none',
                borderRadius: '4px'
            }}
        >
            {
                user ? 
                <>
                <RelativeContainer
                    style={{
                        height: 50,
                        flexFlow: 'row',
                        fontSize: '16px'
                    }}
                >
                    <img src={accountIcon} style={{ height: 20, width: 20, margin: '10px' }} alt='account' />
                    {user.email}
                </RelativeContainer>
                {/* <RelativeContainer
                    onClick={signOut}
                    style={{
                        cursor: 'pointer',
                        height: 30,
                        padding: '10px 20px',
                        boxSizing: 'border-box',
                        flexFlow: 'row',
                }}
                >
                    SIGN OUT
                </RelativeContainer> */}
                <Button containerStyle={{margin: 0}} text='SIGN OUT' onClick={signOut}/>
                </>
                :
                null
            }
            <CloseButton onClick={deactivate}/>

        </RelativeContainer>
    )
}

export default ({ user, signIn, signOut, height, registerAccount, active, deactivate, contentWidth }) => {

    return (
        <AbsoluteContainer
            onClick={e => e.stopPropagation()}
            style={{
                position: 'fixed',
                zIndex: 3,
                top: 0,
                left: 0,
                height,
                pointerEvents: active ? 'auto' : 'none',
                opacity: active ? 1 : 0,
                background: 'rgba(0, 0, 0, 0.7)',
                transition: 'opacity 250ms ease'
            }}
        >
            <SignInBox width={contentWidth} deactivate={deactivate} user={user} active={active && !user} signIn={signIn} registerAccount={registerAccount} />
            <AccountBox width={contentWidth} deactivate={deactivate} user={user} active={user && active} signOut={signOut} />

        </AbsoluteContainer>
    )

}