import React, { useState, useEffect, useCallback, useMemo } from 'react'
import RelativeContainer from '../containers/RelativeContainer'
import AbsoluteContainer from '../containers/AbsoluteContainer'

import TextInput from '../inputs/TextInput'
import OptionBox from '../inputs/OptionBox'
import ResizeObserver from 'react-resize-observer'
import SuggestionInput from './SuggestionInput'
import SubmitButton from './SubmitButton'

const constructFieldObject = (fields, values) => {
    let fieldObject = {}
    for (let i in fields) {
        let data = { ...fields[i], value: values[i] }
        fieldObject[data.name] = data.value
    }

    return fieldObject
}


function ErrorMessage({ error }) {

    return (
        <RelativeContainer
            style={{ color: '#cd0000', height: error ? 'auto' : 0, background: '#fafafa', overflow: 'hidden', textAlign: 'center', boxSizing: 'border-box', padding: error ? '10px 20px' : 0 }}
        >
            {error}
        </RelativeContainer>
    )
}

function FormWithButton({ fields, active, callback, initValues, onChange, onResize, submitText = 'SUBMIT', deactivate, index }) {

    const [values, setValues] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (active) {
            if (!values) {
                if (initValues) {
                    setValues(initValues)
                }
                else {
                    setValues(fields.map(option => ''))
                }
            }
        }
        else {
            setValues(null)
        }
    }, [fields, values, active, initValues])


    useEffect(() => {
        if (error) {
            setTimeout(() => setError(null), 3000)
        }
    }, [error])

    const handleFieldChange = useCallback((index, value) => {
        if (values) {
            let newVals = [...values]
            newVals[index] = value
            setValues(newVals)
        }
    }, [values])

    useEffect(() => {
        if (values && onChange) {
            onChange(index, values)
        }
    }, [onChange, values, index])

    const formComplete = useMemo(() => {
        if (values) {
            let complete = true
            for (let i in values) {
                if (!values[i] && !fields[i].optional) complete = false
            }
            return complete
        }
        return false
    }, [values, fields])

    const handleSubmit = useCallback(() => {
        if (formComplete) {
            setLoading(true)
            let completedFields = constructFieldObject(fields, values)
            callback(completedFields, ({ success, error }) => {
                setLoading(false)
                if (error) {
                    setError(error)
                }
                else {
                    setValues(null)
                }
            })
        }
        else {
            setError('All fields are required')
        }
    }, [fields, values, formComplete, callback])

    return (
        <AbsoluteContainer
            style={{
                height: 'auto',
                background: '#fafafa',
                pointerEvents: active ? 'auto' : 'none',
                opacity: active ? 1 : 0,
                transition: 'opactiy 500ms',
                top: '50px',
            }}
        >
            <ResizeObserver onResize={onResize} />
            <form
                style={{
                    height: 'auto',
                    width: '100%'
                }}
            >
                {
                    (values && fields) ?
                        fields.map((fieldData, index) => {
                            switch (fieldData.type) {
                                case 'suggestion':
                                    return <SuggestionInput
                                        index={index}
                                        length={fields.length}
                                        key={`input_${index}_${fieldData.name}`}
                                        inputs={fields.length}
                                        autocomplete={fieldData.autocomplete}
                                        placeholder={fieldData.placeholder}
                                        options={fieldData.options}
                                        value={values ? values[index] : ''}
                                        onChange={value => handleFieldChange(index, value)}
                                    />
                                default:
                                    return <TextInput
                                        key={`input_${index}_${fieldData.name}`}
                                        index={index}
                                        autocomplete={fieldData.autocomplete}
                                        password={fieldData.type === 'password'}
                                        placeholder={fieldData.placeholder}
                                        value={values ? values[index] : ''}
                                        onChange={value => handleFieldChange(index, value)}
                                    />
                            }
                        })
                        :
                        null
                }
            </form>
            <SubmitButton deactivate={deactivate} loading={loading} active={formComplete} text={submitText} onSubmit={handleSubmit} />
            <ErrorMessage error={error} />
        </AbsoluteContainer>
    )
}


export default ({ options, active, initValues, deactivate, onChange, width }) => {

    const [selectedIndex, setSelectedIndex] = useState(0)
    const [formHeights, setFormHeights] = useState([])

    const handleFormResize = useCallback((index, height) => {
        let newHeights = [...formHeights]
        newHeights[index] = height
        setFormHeights(newHeights)
    }, [formHeights])


    const height = useMemo(() => active ? (formHeights[selectedIndex] ? formHeights[selectedIndex] + 50 : 50) : 0, [formHeights, selectedIndex, active])

    return (
        <RelativeContainer
            onClick={e => e.stopPropagation()}
            style={{
                width: width || '100%',
                background: '#fafafa',
                justifyContent: 'flex-start',
                borderRadius: '4px',
                transition: 'height 500ms',
                height
            }}
        >
            <OptionBox options={options} select={index => setSelectedIndex(index)} selectedIndex={selectedIndex} />
            {
                options.map((option, index) =>
                    <FormWithButton
                        index={index}
                        onChange={onChange}
                        initValues={initValues ? initValues[index] : null}
                        deactivate={deactivate}
                        submitText={option.submitText}
                        key={`form_option_${index}`}
                        onResize={({ height }) =>
                        handleFormResize(index, height)}
                        fields={option.fields}
                        callback={option.callback}
                        active={active && (selectedIndex === index)}
                    />
                )
            }
        </RelativeContainer>
    )
}