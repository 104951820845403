import { useState, useEffect, useMemo, useCallback } from "react";
import { getDateRange } from "./clientUtils";
import { getDurationString } from "../../utils/timeUtils";
import { sum, isEqual } from "lodash";

export default ({ projects, records }) => {
  const [hiddenProjects, setHiddenProjects] = useState([]);
  const [clientDateRange, setClientDateRange] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [initDateRange, setInitDateRange] = useState(null);
  const [selectedPresetText, setSelectedPresetText] = useState(null);

  useEffect(() => {
    if (projects || records) {
      let dateRange = getDateRange({ projects, records });
      setClientDateRange(dateRange);
    }
  }, [projects, records]);

  useEffect(() => {
    if (clientDateRange) {
      if (!initDateRange) {
        setInitDateRange(clientDateRange);
      }
    } else {
      setInitDateRange(null);
    }
  }, [clientDateRange, initDateRange]);

  useEffect(() => {
    if (initDateRange) {
      setStartDate(initDateRange.min);
      setEndDate(initDateRange.max);
    }
  }, [initDateRange]);

  const inDateRange = useCallback(
    (record) => {
      if (startDate && endDate) {
        if (record.startTime <= endDate) {
          if (record.startTime >= startDate) {
            return true;
          }
        }
      }
      return false;
    },
    [startDate, endDate]
  );

  const handleResetDates = useCallback(() => {
    if (initDateRange) {
      setSelectedPresetText(null);
      setStartDate(initDateRange.min);
      setEndDate(initDateRange.max);
    }
  }, [initDateRange]);

  const handleToggleProject = useCallback(
    (projectName) => {
      let index = hiddenProjects.indexOf(projectName);
      if (index === -1) {
        setHiddenProjects([...hiddenProjects, projectName]);
      } else {
        let newProjects = [...hiddenProjects];
        newProjects.splice(index, 1);
        setHiddenProjects(newProjects);
      }
    },
    [hiddenProjects]
  );

  const filteredRecords = useMemo(() => {
    if (records || projects) {
      if (startDate && endDate) {
        let tempRecords = [];
        if (projects) {
          Object.keys(projects).forEach((projectName) => {
            if (hiddenProjects.indexOf(projectName) === -1) {
              tempRecords = tempRecords.concat(
                projects[projectName].records.filter((record) =>
                  inDateRange(record)
                )
              );
            }
          });
        }

        if (records) {
          tempRecords = tempRecords.concat(
            records.filter((record) => inDateRange(record))
          );
        }

        return tempRecords;
      }
    }

    return [];
  }, [records, projects, hiddenProjects, startDate, endDate, inDateRange]);

  const totalTime = useMemo(() => {
    if (filteredRecords) {
      return getDurationString(
        sum(
          filteredRecords.map(
            (record) => (record.endTime || Date.now()) - record.startTime
          )
        )
      );
    }
    return 0;
  }, [filteredRecords]);

  const dateChanges = useMemo(
    () => !isEqual({ min: startDate, max: endDate }, initDateRange),
    [startDate, endDate, initDateRange]
  );

  return {
    filteredRecords,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    dateChanges,
    handleResetDates,
    selectedPresetText,
    handleToggleProject,
    setHiddenProjects,
    hiddenProjects,
    totalTime,
    setSelectedPresetText,
  };
};
