import TrackPage from "./TrackPage";
import ClientPage from "./ClientPage";
import InvoicePage from "./InvoicePage";
import HomePage from './HomePage'

export default [
  {
    name: "track",
    path: "/",
    menu: true,
    component: TrackPage,
  },
  {
    name: "test",
    path: "/test",
    menu: true,
    component: HomePage,
  },
  {
    name: "clients",
    path: "/clients",
    menu: true,
    component: ClientPage,
  },
  {
    name: "invoices",
    path: "/invoices",
    menu: true,
    component: InvoicePage,
  },
];
