
export const getAllRecords = ({ projects, records }) => {

    let allRecords = []
    if (projects) Object.values(projects).forEach(({ records }) => allRecords = allRecords.concat(records))
    if (records) allRecords = allRecords.concat(records)

    return allRecords
}

export const getDateRange = ({ projects, records }) => {
    let allRecords = getAllRecords({ projects, records })

    if (allRecords.length) {
        let min = Date.now()
        let max = 0
        for (let i in allRecords) {
            if (allRecords[i].startTime < min) {
                min = allRecords[i].startTime
            }
            if (allRecords[i].startTime > max) {
                max = allRecords[i].startTime
            }
        }

        return { max, min }
    }

    return null

}