import React, { useState, useCallback } from 'react'
import RelativeContainer from '../containers/RelativeContainer'
import ClientItem from './ClientItem'
import useSlideIn from '../../hooks/useSlideIn'

export default ({ clients, width, trackData, selectSession, assignErrorCode, generateInvoice, updateClientInfo, active }) => {

    const [selectedClientIndex, setSelectedClientIndex] = useState(null)

    const handleSelect = useCallback(index => {
        if (index === selectedClientIndex) {
            setSelectedClientIndex(null)
        }
        else {
            setSelectedClientIndex(index)
        }
    }, [selectedClientIndex])

    const listSlideIn = useSlideIn({show: active})
    return (
        <RelativeContainer
            style={{
                width,
                paddingTop: '20px',
                height: 'auto',
                ...listSlideIn
            }}
        >
            {
                clients ? clients.map((client, index) =>
                    <ClientItem
                        assignErrorCode={assignErrorCode}
                        generateInvoice={generateInvoice}
                        first={index === 0}
                        key={`client_${client.name}`}
                        updateClientInfo={updateClientInfo}
                        index={index}
                        trackData={trackData}
                        selected={selectedClientIndex === index}
                        select={() => handleSelect(index)}
                        client={client}
                        selectSession={selectSession}
                    />
                )
                    :
                    null
            }
        </RelativeContainer>
    )
}