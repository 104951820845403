import { useState, useMemo, useEffect, useRef } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";

import firebaseConfig from "./firebasecfg";
import useTrackData from "./useTrackData";
import useOnSnapshot from "./useOnSnapshot";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

const auth = firebase.auth();
const db = firebase.firestore();

export default () => {
  const [user, setUser] = useState(null);

  const {data: clients, update: updateClientInfo} = useOnSnapshot(user, db, "clients");
  const trackData = useTrackData({ user, clients });
  const subscribeRef = useRef();

  const admin = useMemo(() => (user ? user.role === "admin" : false), [user]);


  useEffect(() => {
    auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        subscribeRef.current = db
          .collection("users")
          .doc(currentUser.uid)
          .onSnapshot((doc) => {
            setUser({ ...doc.data(), id: currentUser.uid });
          });
      } else {
        setUser(null);
        if (subscribeRef.current) subscribeRef.current();
      }
    });

    return () => {
      if (subscribeRef.current) subscribeRef.current();
    };
  }, []);

  const signIn = (fields, callback) => {
    let { email, password } = fields;
    if (email) {
      if (password) {
        auth
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            auth
              .signInWithEmailAndPassword(email, password)
              .then(() => {
                callback({ success: true });
              })
              .catch((err) => {
                console.log(err);
                callback({ error: err.message });
              });
          })
          .catch((err) => {
            console.log(err);
            callback({ error: "Persistence error" });
          });
      } else {
        callback({ error: "Password is required" });
      }
    } else {
      callback({ error: "Email is required" });
    }
  };

  const signOut = () => {
    setUser(null);
    auth.signOut().catch((err) => {
      console.log(err);
      setUser(null);
    });
  };

  const registerAccount = (fields, callback) => {
    let { email, password, confirmPassword } = fields;
    if (email) {
      if (password && password === confirmPassword) {
        auth
          .createUserWithEmailAndPassword(email, password)
          .then((res) => {
            console.log(res.user);
            db.collection("users")
              .doc(res.user.uid)
              .set({ email })
              .then(() => {
                callback({ success: true });
                setUser({ email, id: res.user.uid });
              })
              .catch((err) => {
                console.log(err);
                callback({ error: "Database error." });
              });
          })
          .catch((err) => {
            console.log(err);
            callback({ error: err.message });
          });
      } else {
        callback({ error: "Passwords do not match" });
      }
    } else {
      callback({ error: "Email is required" });
    }
  };

  return { user, clients, updateClientInfo, trackData, admin, signIn, signOut, registerAccount };
};
