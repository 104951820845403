import React, { useState, useMemo, useCallback } from "react";
import AbsoluteContainer from "../containers/AbsoluteContainer";
import FormSelector from "../inputs/FormSelector";

export default function TrackDialog({
  title = "NEEDS TITLE",
  clients,
  trackData,
  projects,
  deactivate,
  callback,
  selectedSession,
  width,
}) {
  const clientOptions = useMemo(
    () => (clients ? clients.map((client) => client.name) : null),
    [clients]
  );
  const [projectOptions, setProjectOptions] = useState([]);

  const handleFormChange = useCallback(
    (_, fields) => {
      let clientName = fields[0];
      if (clientName && trackData && trackData.track) {
        let opts = [];
        for (let i in trackData.track) {
          let record = trackData.track[i];
          if (record.client && (record.client.name === clientName)) {
            if (record.projectName && opts.indexOf(record.projectName) === -1)
              opts.push(record.projectName);
          }
        }
        setProjectOptions(opts)
      } else {
        setProjectOptions(null);
      }
    },
    [trackData]
  );

  const options = useMemo(
    () => [
      {
        title,
        fields: [
          {
            name: "clientName",
            placeholder: "Client",
            type: "suggestion",
            options: clientOptions,
          },
          {
            name: "projectName",
            placeholder: "Project",
            type: "suggestion",
            optional: true,
            options: projectOptions,
          },
          {
            name: "description",
            optional: true,
            placeholder: "Description",
          },
        ],
        submitText: "Save",
        callback,
      },
    ],
    [title, callback, projectOptions, clientOptions]
  );

  const initValues = useMemo(() => {
    if (selectedSession) {
      let vals = [];
      if (selectedSession.client && selectedSession.client.name) {
        vals.push(selectedSession.client.name);
      } else {
        vals.push("");
      }

      if (selectedSession.projectName) vals.push(selectedSession.projectName);
      else vals.push("");

      if (selectedSession.description) vals.push(selectedSession.description);
      else vals.push("");

      return [vals];
    } else {
      return [["", "", ""]];
    }
  }, [selectedSession]);

  return (
    <AbsoluteContainer
      style={{
        position: "fixed",
        top: "0px",
        left: "0px",
        pointerEvents: selectedSession ? "auto" : "none",
        background: "rgba(0,0,0,0.8)",
        opacity: selectedSession ? 1 : 0,
        transition: "opacity 500ms",
      }}
    >
      <FormSelector
        onChange={handleFormChange}
        width={width}
        deactivate={deactivate}
        options={options}
        initValues={initValues}
        active={selectedSession}
      />
    </AbsoluteContainer>
  );
}
