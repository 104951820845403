import React from 'react'

import TrackPanel from '../track/TrackPanel'


export default ({ setMessage, user, trackData, contentWidth, bodyHeight, selectSession }) => {
    

    return (
        <TrackPanel contentWidth={contentWidth} setMessage={setMessage} user={user} height={bodyHeight} selectSession={selectSession} trackData={trackData} />

    )
}