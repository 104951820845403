import React from 'react'
import AbsoluteContainer from '../containers/AbsoluteContainer'


export default ({ onClick }) => {

    return (
        <AbsoluteContainer
            onClick={onClick}
            style={{
                cursor: 'pointer',
                top: '0px',
                right: '0px',
                width: 25,
                height: 25,
                fontSize: '21',
                color: '#444',
                fontFamily: 'Muli'
            }}
        >
            x
        </AbsoluteContainer>
    )

}