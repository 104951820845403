import React, {
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import RelativeContainer from "../containers/RelativeContainer";
import { Document, Page, pdfjs } from "react-pdf";

import useInvoice from "./useInvoice";
import AbsoluteContainer from "../containers/AbsoluteContainer";

import loadingIcon from "../../icons/loading.svg";
import zoomIcon from "../../icons/zoom.svg";
import zoomOutIcon from "../../icons/zoom_out.svg";
import { useSpring, animated } from "react-spring";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const localDownloadFile = (file, fileName) => {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(file, fileName);
  } else {
    let link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(file);
    link.dispatchEvent(new MouseEvent("click"));
  }
};

function DownloadButton({ onDownload, onToggleZoom, zoom }) {
  const [hovered, setHovered] = useState(false);

  return (
    <AbsoluteContainer
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        top: 40,
        position: "fixed",
        zIndex: 3,
        opacity: onDownload ? (hovered ? 0.9 : 0.8) : 0,
        pointerEvents: onDownload ? "auto" : "none",
        transition: "opacity 500ms",
        borderRadius: 15,
        flexFlow: "row",
        padding: "0px 15px",
        fontSize: "12px",
        height: 30,
        width: "auto",
        background: "black",
        color: "white",
      }}
    >
      <span
        onClick={onDownload}
        style={{
          cursor: "pointer",
        }}
      >
        DOWNLOAD
      </span>
      <img
        onClick={onToggleZoom}
        src={zoom ? zoomOutIcon : zoomIcon}
        alt="zoom"
        style={{ height: 22, width: 22, marginLeft: "8px", cursor: "pointer" }}
      />
    </AbsoluteContainer>
  );
}

function LoadingIcon({ loading }) {
  return (
    <AbsoluteContainer
      style={{
        pointerEvents: loading ? "auto" : "none",
        background: "white",
        opacity: loading ? 1 : 0,
        transition: "opacity 500ms",
        zIndex: 1,
        left: 0,
        top: 0,
      }}
    >
      <img alt="loading" style={{ height: "20%" }} src={loadingIcon} />
    </AbsoluteContainer>
  );
}

const aspectRatio = 210 / 297;
export default forwardRef(
  ({ screenWidth, user, invoiceData, screenHeight, bodyHeight, example }, ref) => {
    const [zoom, setZoom] = useState(false);
    const [loading, setLoading] = useState(true);

    const zoomScale = useMemo(() => screenHeight / (bodyHeight - 200), [
      screenHeight,
      bodyHeight,
    ]);

    const { blob, handleUserNameChange } = useInvoice({ invoiceData, user, example });
    const loadingTimer = useRef();

    const handleDownload = useCallback(() => {
      if (blob) {
        localDownloadFile(blob, "Example Invoice.pdf");
      }
    }, [blob]);

    let documentHeight = useMemo(() => bodyHeight - 200, [bodyHeight]);
    let documentWidth = useMemo(() => documentHeight * aspectRatio, [
      documentHeight,
    ]);

    useEffect(() => {
      setLoading(true);
      if (loadingTimer.current) clearTimeout(loadingTimer.current);
      loadingTimer.current = setTimeout(() => setLoading(false), 1000);

      return () => {
        if (loadingTimer.current) clearTimeout(loadingTimer.current);
      };
    }, [invoiceData, documentWidth, documentHeight, zoom]);

    const { translateX, docWidth, docHeight, docOpacity } = useSpring({
      translateX:
        zoom && documentWidth * zoomScale > screenWidth
          ? (documentWidth * zoomScale - screenWidth) / 2
          : 0,
      docWidth: zoom ? documentWidth * zoomScale : documentWidth,
      docHeight: zoom ? documentHeight * zoomScale : documentHeight,
      docOpacity: blob ? 1 : 0,
    });
    return (
      <AbsoluteContainer
        style={{
          left: 0,
          top: 0,
          position: "fixed",
        }}
      >
        <RelativeContainer
          style={{
            overflowX:
              zoom && documentWidth * zoomScale > screenWidth
                ? "auto"
                : "hidden",
          }}
        >
          <animated.div
            onClick={() => handleUserNameChange("test")}
            style={{
              fontFamily: "Helvetica",
              justifyContent: "flex-start",
              position: "relative",
              overflow: "hidden",
              background: "white",
              opacity: docOpacity,
              width: docWidth,
              height: docHeight,
              transform: translateX.interpolate(
                (val) => `translate(${val}px,0px)`
              ),
            }}
          >
            <RelativeContainer
              style={{
                opacity: loading ? 0 : 1,
              }}
            >
              <Document
                onLoadError={(e) => console.log(e)}
                noData=""
                error=""
                loading=""
                file={blob}
              >
                <Page
                  noData=""
                  error=""
                  loading=""
                  height={documentHeight}
                  scale={zoom ? zoomScale : 1}
                  pageNumber={1}
                />
              </Document>
            </RelativeContainer>
            <LoadingIcon loading={loading} />
          </animated.div>
        </RelativeContainer>

        <DownloadButton
          zoom={zoom}
          onToggleZoom={() => setZoom((zoom) => !zoom)}
          onDownload={handleDownload}
        />
      </AbsoluteContainer>
    );
  }
);
