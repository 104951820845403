export default {
    records: [
      {
        id: "po1wwGiVW9QN0MsrgvQx",
        description: "Starting invoices",
        startTime: 1598682165806,
        endTime: 1598690627839,
        projectName: "Invoice Generator",
      },
      {
        id: "sHDUi0dOPDHvszt1LJk0",
        description: "Starting invoices",
        projectName: "Invoice Generator",
        startTime: 1598680155143,
        endTime: 1598682121246,
      },
      {
        id: "AeQt76d1VvDJpEHUfWyK",
        startTime: 1598225028349,
        description: null,
        endTime: 1598225043113,
        projectName: "Invoice Generator",
      },
      {
        id: "MOSDNSnf5jkdmKWGA9Ne",
        description: null,
        endTime: 1598082430995,
        startTime: 1598082188373,
        projectName: "Invoice Generator",
      },
      {
        id: "PNpi5QbRDcDSathyiUmO",
        startTime: 1598075850195,
        description: null,
        endTime: 1598078389918,
        projectName: "Invoice Generator",
      },
      {
        id: "EnRg6PJ5evyrTy69OQWu",
        endTime: 1598075014619,
        projectName: "Invoice Generator",
        startTime: 1598073878873,
        description: "Polish Track Page, begin Clients Page",
      },
      {
        id: "8rG7UPzSLqRihIwX6rsI",
        description: "Polish Track Page, begin Clients Page",
        startTime: 1598002247973,
        endTime: 1598004932224,
        projectName: "Invoice Generator",
      },
      {
        id: "fHtcd4pnz8YVWPrxiZTE",
        startTime: 1597991464659,
        endTime: 1598002242959,
        description: "Polish Track Page, begin Clients Page",
        projectName: "Invoice Generator",
      },
      {
        id: "bBDsebsw8TGVJRck4qLi",
        description: "Polish Track Page, begin Clients Page",
        startTime: 1597968402256,
        endTime: 1597979126909,
        projectName: "Invoice Generator",
      },
      {
        id: "RnKjBz02WXbm9vvvLk3a",
        projectName: "Invoice Generator",
        startTime: 1597838850411,
        endTime: 1597868215107,
        description: "Testing overnight session",
      },
    ],
    client: {
      companyName: 'Gramazon',
      name: 'Jeff Pezos',
      email: "jeff@pezos.com",
      rate: '25.00',
    },
  };
  