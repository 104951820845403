import React from 'react'
import RelativeContainer from '../containers/RelativeContainer'
import SessionItem from './SessionItem'

export default function TrackList({ trackData, width, selectSession }) {

    const {duration, currentSession, track, stopRecording, startRecording, deleteSession} = trackData

    return (
        <RelativeContainer
            style={{
                width,
                display: 'block',
                boxSizing: 'border-box',
                height: 'auto'
            }}
        >
            {
                track  && track.length ?
                    track.map((session, index) =>
                        <SessionItem
                            key={`session_${session.id}`}
                            selectSession={selectSession}
                            deleteSession={deleteSession}
                            startRecord={startRecording}
                            stopRecord={stopRecording}
                            currentSession={currentSession ? (currentSession.id === session.id) : false}
                            duration={duration}
                            session={session}
                        />
                    )
                    :
                    null
            }
        </RelativeContainer>
    )
}