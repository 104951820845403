import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { isEqual, cloneDeep } from "lodash";

export default ({ clientInfo, assignErrorCode, updateClientInfo, alwaysSave }) => {
  const [editedClientInfo, setEditedClientInfo] = useState(null);
  const [clientInfoError, setClientInfoError] = useState(null);
  const clientInfoErrorTimeout = useRef();
  useEffect(() => {
    if (clientInfoErrorTimeout.current)
      clearTimeout(clientInfoErrorTimeout.current);
    if (clientInfoError) {
      clientInfoErrorTimeout.current = setTimeout(
        () => setClientInfoError(null),
        2500
      );
    }
  }, [clientInfoError]);
  useEffect(() => {
    setEditedClientInfo(clientInfo);
  }, [clientInfo]);

  const clientInfoChanges = useMemo(
    () => !isEqual(clientInfo, editedClientInfo),
    [editedClientInfo, clientInfo]
  );

  const handleFieldChange = useCallback(
    (fieldName, value) => {
      if (editedClientInfo) {
        let aux = cloneDeep(editedClientInfo);
        if (value) {
          aux[fieldName] = value;
        } else {
          aux[fieldName] = undefined;
        }

        setEditedClientInfo(aux);
      }
    },
    [editedClientInfo]
  );

  const handleRateChange = useCallback(
    (newRate) => {
      if (editedClientInfo) {
        if (newRate) {
          if (newRate[0] === "$") newRate = newRate.slice(1);
          if (isNaN(newRate)) {
            setClientInfoError("Rate must be a number");
          } else {
            let dotIndex = newRate.indexOf(".");
            if (dotIndex !== -1) {
              let numDecimals = newRate.length - dotIndex - 2;
              if (numDecimals < 2) {
                handleFieldChange("rate", newRate);
              }
            } else {
              handleFieldChange("rate", newRate);
            }
          }
        } else {
          handleFieldChange("rate", null);
        }
      }
    },
    [handleFieldChange, editedClientInfo]
  );

  const handleRateBlur = useCallback(() => {
    if (editedClientInfo) {
      if (editedClientInfo.rate) {
        let rate = parseFloat(editedClientInfo.rate);

        if (isNaN(rate)) {
          setClientInfoError(assignErrorCode());
        } else {
          handleFieldChange("rate", rate.toFixed(2));
        }
      }
    }
  }, [editedClientInfo, assignErrorCode, handleFieldChange]);

  const handleClientInfoSubmit = useCallback(() => {
    if (clientInfoChanges || alwaysSave) {
      updateClientInfo(editedClientInfo, ({ error, success }) => {
        if (error) {
          setClientInfoError(error);
        }
      });
    } else {
      setClientInfoError(``);
    }
  }, [editedClientInfo, clientInfoChanges, updateClientInfo, alwaysSave]);

  const handleClientInfoReset = useCallback(() => {
    setEditedClientInfo(clientInfo);
  }, [clientInfo]);

  return {
    editedClientInfo,
    clientInfoError,
    clientInfoChanges,
    handleClientInfoSubmit,
    handleFieldChange,
    handleRateChange,
    handleRateBlur,
    handleClientInfoReset,
  };
};
