import React from "react";
import SlidingButton from "./SlidingButton";
export default function AMPMButton({ active, pm, setPm }) {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        height: 30,
        width: 100,
        alignItems: "center",
        fontSize: "20px",
      }}
    >
      <SlidingButton
        text="AM"
        onClick={() => setPm((pm) => !pm)}
        active={active && !pm}
      />
      <SlidingButton
        text="PM"
        up={false}
        onClick={() => setPm((pm) => !pm)}
        active={active && pm}
      />
    </div>
  );
}
