import React from 'react'
import AbsoluteContainer from '../containers/AbsoluteContainer'
import deleteIcon from '../../icons/delete.svg'

export default function DeleteButton({ onDelete }) {

    const handleClick = e => {
        e.stopPropagation()
        onDelete()
    }

    return (
        <AbsoluteContainer
            title='Delete Record'
            style={{
                right: '15px', top: '15px',
                cursor: 'pointer',
                height: '30px',
                width: '30px'
            }}
        >
            <img onClick={handleClick} src={deleteIcon} style={{ height: '100%', width: '100%', filter: `drop-shadow(0 0 3px #333)` }} alt='delete' />
        </AbsoluteContainer>
    )
}