import React, { forwardRef } from 'react'

export default forwardRef(({style, children, ...props}, ref) => {

    return(
        <div
            ref={ref}
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                ...style
            }}
            {...props}
        >
            {children}
        </div>
    )
})