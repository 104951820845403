import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useEffect, useState, useMemo } from "react";
import { getNumHours, getDateString } from "../../utils/timeUtils";
import { cloneDeep } from "lodash";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const defaultLogo = "/images/logo.png";

export default ({ invoiceData, user, example }) => {
  const [blob, setBlob] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoPath] = useState(defaultLogo);

  useEffect(() => {
    if (logoPath) {
      fetch(logoPath)
        .then((res) => {
          let reader = new FileReader();
          res.blob().then((blob) => {
            reader.readAsDataURL(blob);
            reader.onerror = (e) => {
              console.log(e);
            };
            reader.onloadend = () => {
              setLogo(reader.result);
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLogo(null);
    }
  }, [logoPath]);

  const getDocText = (text, right = true, style) => {
    if (style)
      return {
        alignment: right ? "right" : "left",
        text,
        style,
      };
    return {
      alignment: right ? "right" : "left",
      text,
    };
  };

  const dd = useMemo(() => {
    const hr = (color) => ({
      canvas: [
        {
          type: "line",
          lineColor: color || "#000000",
          x1: 0,
          y1: 10,
          x2: 595 - 100,
          y2: 10,
          lineWidth: 0.5,
        },
      ],
    });

    const getLogoSection = () => {
      if (logo) {
        return { image: logo, fit: [200, 100] };
      } else {
        return {};
      }
    };

    const getRecordSection = (record, client) => {
      let recordItems = [];
      let otherItems = [];

      let numHours = getNumHours(record.endTime - record.startTime);

      if (record.startTime) {
        recordItems.push({
          text: getDateString(record.startTime, null, false),
          margin: [0, 10, 0, 0],
          decoration: "underline",
          lineHeight: 1.4,
        });
        if (record.endTime) {
          otherItems.push({
            text: `${numHours.toFixed(2)} hours`,
            alignment: "right",
            margin: [0, 10, 0, 0],
          });
        }
      }

      let rate = parseFloat(record.rate);
      if (isNaN(rate)) {
        rate = parseFloat(client.rate);
      } else {
        console.error("NO RATE ON CLIENT OR RECORD");
      }

      otherItems.push({
        text: `$${rate.toFixed(2)}/hr`,
        decoration: "underline",
        fontSize: 13,
        color: "#333333",
        alignment: "right",
      });

      let recordTotal = rate * numHours;

      otherItems.push({
        text: `$${recordTotal.toFixed(2)}`,
        fontSize: 16,
        alignment: "right",
        margin: [0, 5],
      });

      if (record.projectName) {
        recordItems.push({ text: record.projectName, style: "bigger" });
      }

      if (record.description) {
        recordItems.push({ text: record.description, margin: [0, 0, 0, 10] });
      }
      return [recordItems, otherItems];
    };

    const getInvoiceRecords = () => {
      let items = [];

      if (invoiceData && invoiceData.records) {
        let sortedRecords = invoiceData.records.sort((a, b) =>
          a.startTime < b.startTime ? -1 : 1
        );

        for (let i in sortedRecords) {
          let record = sortedRecords[i];
          if (record.startTime && record.endTime) {
            items.push(getRecordSection(record, invoiceData.client));
          }
        }
      }

      return {
        table: {
          widths: ["*", "*"],
          body: items,
          dontBreakRows: true,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 2;
          },
          vLineWidth: function (i, node) {
            return 0;
          },
          hLineColor: "#cdcdcd",
        },
      };
    };

    const getUserAddressSection = () => {
      let items = [];

      if (user.addressLine1 || user.addressLine2) {
        items.push(
          getDocText(
            `${user.addressLine1 ? user.addressLine1 : ""}${
              user.addressLine2 ? " " + user.addressLine2 : ""
            }`
          )
        );
      }

      if (user.addressLevel1 || user.addressLevel2 || user.postalCode) {
        items.push(
          getDocText(
            `${user.addressLevel2 ? user.addressLevel2 + ", " : ""}${
              user.addressLevel1 ? user.addressLevel1 : ""
            }${user.postalCode ? " " + user.postalCode : ""}`
          )
        );

        if (user.country) {
          items.push(getDocText(user.country));
        }
      }

      return items;
    };

    const getUserDataSection = () => [
      {
        alignment: "right",
        text: "Invoice",
        style: "header",
      },
      {
        alignment: "right",
        style: "bigger",
        text: user ? user.name : "",
      },
      ...getUserAddressSection(),
      {
        margin: [0, 10, 0, 0],
        alignment: "right",
        text: user ? user.phone : "",
      },
      {
        alignment: "right",
        text: user ? user.email : "",
        margin: [10, 0, 0, 0],
      },
    ];

    const getClientDataSection = () => {
      let items = [];

      if (invoiceData.client) {
        items.push(getDocText("Billed To:", false, "smallbold"));
        let client = invoiceData.client;
        if (client.companyName) {
          items.push(getDocText(client.companyName, false, "bigbold"));
        }
        if (client.name) {
          items.push(getDocText(client.name, false, "bigger"));
        }
        if (client.email) {
          items.push({
            text: client.email,
            alignment: "left",
          });
        }
      }

      return items;
    };

    const getInvoiceSummarySection = () => {
      const totalAmount = invoiceData
        ? invoiceData.records
            .reduce(
              (acc, curr) =>
                acc +
                getNumHours(curr.endTime - curr.startTime) *
                  (curr.rate || invoiceData.client.rate),
              0
            )
            .toFixed(2)
        : null;
      // const dateRange = invoiceData ? getDateRange(invoiceData) : null;
      // const dateString = invoiceData
      //   ? getDateString(dateRange.min, dateRange.max, false)
      //   : null;

      const today = getDateString(Date.now(), null, false);

      const items = [];

      items.push(getDocText(`Invoice Summary`, true, "smallHeader"));

      if (today) {
        items.push(getDocText(`Invoice date: ${today}`, true));
      }

      if (totalAmount) {
        items.push({
          text: `Total: $${totalAmount}`,
          alignment: "right",
          style: "bigbold",
          fontSize: 15,
          margin: [0, 5, 0, 0],
        });
      }

      return items;
    };

    return {
      content: [
        {
          columns: [getLogoSection(), getUserDataSection()],
        },
        hr(),
        {
          columns: [getClientDataSection(), getInvoiceSummarySection()],
        },
        hr(),
        getInvoiceRecords(),
      ],
      watermark: example ? { text: "EXAMPLE INVOICE", opacity: 0.1 } : null,
      pageMargins: 50,
      styles: {
        header: {
          fontSize: 26,
          margin: [0, 5, 0, 0],
          bold: true,
        },
        smallHeader: {
          fontSize: 15,
          margin: [0, 10, 0, 0],
        },
        smallbold: {
          fontSize: 15,
          margin: [0, 10, 0, 0],
          bold: true,
        },
        bigbold: {
          fontSize: 18,
          margin: [0, 5, 0, 5],
          bold: true,
        },
        bigger: {
          fontSize: 15,
        },
      },
      defaultStyle: {
        columnGap: 20,
        fontSize: 13,
      },
    };
  }, [example, logo, invoiceData, user]);

  useEffect(() => {
    setBlob(null);
    if (dd.content) {
      pdfMake
        .createPdf(dd, () => {})
        .getBlob((blob) => {
          if (Object.keys(dd.images).length) {
            console.log(cloneDeep(dd));
            //if (dd.content[4].resetXY) {
            console.log(blob);
            setBlob(blob);
            //}
          }
        });
    }
  }, [dd, user]);

  return { blob, logo };
};
